import intersection from 'lodash/intersection';
import { defineStore } from 'pinia';
import { filter, tap, throwError } from 'rxjs';

import { MerchantService } from '~/support/services';
import createBus from '~/support/utils/event-bus';
import type { Brand, Store } from '~/types';

type Capabilities = {
  whitelabel: boolean;
  refund: boolean;
  reship: boolean;
  credit: boolean;
  inventory: boolean;
  splitTest: boolean;
};

export const useMerchantStore = defineStore('merchants', () => {
  const { query, loading } = useGQL();
  const currentStore = ref<Brand | null>(null);
  const availableStores = ref([] as Store[]);
  const service = new MerchantService(useRuntimeConfig().public.apiBaseUrl);
  const lastKnownStore = useStorage('op:last-store');
  const { merchant } = useGateway();

  const route = useRoute();
  const router = useRouter();

  // because selected store can be an object or an array this breaks reactivity
  // by using a bus we just get an event that the object was changed
  // this fixes the watcher not firing when the object is changed
  const $bus = createBus();

  function setCurrentStore(brand: Store | Store[]) {
    if (Array.isArray(brand)) {
      lastKnownStore.set(brand);
      currentStore.value = brand;

      if (route.params.storeSlug) {
        router.push('/claims/todo');
      }

      $bus.emit('store:changed', brand);

      return;
    }

    setStore(brand.id);

    // If we're on a page that's using the storeSlug we need to navigate to the same page with the new store params
    if (route.params.storeSlug && route.params.storeSlug !== brand.slug) {
      router.push({ name: route.name as string, params: { ...route.params, storeSlug: brand.slug } });
    }

    $bus.emit('store:changed', brand);
  }

  function setStoreWithSlug(slug: string) {
    const store = availableStores.value.find(store => store.slug === slug);

    if (store) {
      setStore(store.id);
    }
  }

  const storeCan = (capability: keyof Capabilities) => {
    if (!currentStore.value) {
      return false;
    }

    return (currentStore.value as Brand).capabilities?.[capability];
  };

  const storeSlug = route.params.storeSlug;

  const storeMatchingSlug = computed(() => {
    return (availableStores.value || []).find(store => store.slug === storeSlug);
  });

  const stores = computed(() => {
    const available = availableStores.value?.reduce(
      (acc: { active: Store[]; inactive: Store[]; dev: Store[]; onboarding: Store[] }, store: any) => {
        const storeObject = {
          accountId: store.accountId,
          generalSettings: store.generalSettings,
          id: store.id,
          logo: store.storeSettings?.storeLogoUrl,
          name: store.name,
          platform: store.platformId,
          slug: store.slug,
          ...store,
        };

        switch (store.storeType) {
          case 'ACTIVE':
            acc.active.push(storeObject);
            break;
          case 'ONBOARDING':
            acc.onboarding.push(storeObject);
            break;
          case 'INACTIVE':
            acc.inactive.push(storeObject);
            break;
          case 'DEV':
            acc.dev.push(storeObject);
            break;
        }

        return acc;
      },
      { active: [], dev: [], inactive: [], onboarding: [] },
    );

    return available;
  });

  const findStoreById = (id: string) => {
    return availableStores.value.find(store => store.id === id);
  };

  watch(storeMatchingSlug, store => {
    if (!store) return;

    currentStore.value = store;
  });

  const currentStorePlatform = computed(() => {
    if (currentStore.value) {
      return currentStore.value.platformId;
    }

    return null;
  });

  const numActiveStores = computed(() => {
    return stores.value?.active.length;
  });

  const numOnboardingStores = computed(() => {
    return stores.value?.onboarding.length;
  });

  const numInactiveStores = computed(() => {
    return stores.value?.inactive.length;
  });

  const numDevStores = computed(() => {
    return stores.value?.dev.length;
  });

  function setStore(id: string) {
    const store = availableStores.value.find(store => store.id === id);

    if (store) {
      currentStore.value = store as Brand;
      lastKnownStore.set(store);
    }
  }

  const loadStores = () => {
    return merchant.stores.list().pipe(
      filter(res => !!res),
      tap(res => {
        availableStores.value = (res.data as any) ?? [];

        const firstStore = (res.data as any)[0];

        if (!currentStore.value && !lastKnownStore.get()) {
          setStore(firstStore?.id);
        } else if (lastKnownStore.get()) {
          // check that they have access to all the stores that are in the last known value
          const accessibleStores = res.data as any;
          const lastKnownStores = Array.isArray(lastKnownStore.get()) ? lastKnownStore.get() : [lastKnownStore.get()];
          const hasAccessToAllStores = lastKnownStores.every((store: Store) =>
            accessibleStores.find((s: { id: string }) => s.id === store?.id),
          );

          if (!hasAccessToAllStores) {
            setStore(firstStore?.id);

            return;
          }

          if (Array.isArray(lastKnownStore.get())) {
            currentStore.value = lastKnownStore.get() as Brand[];

            return;
          }

          setStore((lastKnownStore.get() as Brand)?.id);
        }
      }),
    );
  };

  const selectedStore = computed(() => {
    if (currentStore.value) {
      return currentStore.value;
    }

    if (lastKnownStore.get()) {
      return lastKnownStore.get();
    }

    return null;
  });

  const allActiveSelected = computed(() => {
    if (stores.value.active.length === 0 || !Array.isArray(currentStore.value)) {
      return false;
    }

    return (
      intersection(
        (currentStore.value as Brand[]).map(store => store.id),
        stores.value.active.map(store => store.id),
      ).length === stores.value.active.length
    );
  });

  const allInactiveSelected = computed(() => {
    if (stores.value.inactive.length === 0 || !Array.isArray(currentStore.value)) {
      return false;
    }

    return (
      intersection(
        (currentStore.value as Brand[]).map(store => store.id),
        stores.value.inactive.map(store => store.id),
      ).length === stores.value.inactive.length
    );
  });

  const enable = async (capability: string) => {
    if (!currentStore.value) {
      return;
    }

    const store = currentStore.value as Brand;

    const updatedStore = {
      ...store,
      capabilities: {
        ...store.capabilities,
        [capability]: true,
      },
    };

    setCurrentStore(updatedStore as Store);
  };

  const disable = async (capability: string) => {
    if (!currentStore.value) {
      return;
    }

    const store = currentStore.value as Brand;

    const updatedStore = {
      ...store,
      capabilities: {
        ...store.capabilities,
        [capability]: false,
      },
    };

    setCurrentStore(updatedStore as Store);
  };

  const allDevSelected = computed(() => {
    if (stores.value.dev.length === 0 || !Array.isArray(currentStore.value)) {
      return false;
    }

    return (
      intersection(
        (currentStore.value as Brand[]).map(store => store.id),
        stores.value.dev.map(store => store.id),
      ).length === stores.value.dev.length
    );
  });

  const allSelectedCategory = computed(() => {
    if (allActiveSelected.value) {
      return 'Active';
    }

    if (allInactiveSelected.value) {
      return 'Inactive';
    }

    if (allDevSelected.value) {
      return 'Dev';
    }

    return null;
  });

  const multipleStoresSelected = computed(() => {
    return Array.isArray(currentStore.value) && currentStore.value.length > 1;
  });

  const selectedStoreIds = computed<string | string[]>(() => {
    if (multipleStoresSelected.value) {
      return (currentStore.value as any[])?.map(store => store.id);
    }

    return (currentStore.value as any)?.id;
  });

  async function fetchContactSettings(storeId: string) {
    return service.fetchContactSettings(storeId);
  }

  async function updateContactSettings(storeId: string, settings: any) {
    return service.updateContactSettings(storeId, settings);
  }

  const can = computed(() => {
    return {
      credit: storeCan('credit'),
      inventory: storeCan('inventory'),
      refund: storeCan('refund'),
      reship: storeCan('reship'),
      splitTest: storeCan('splitTest'),
      whitelabel: storeCan('whitelabel'),
    };
  });

  const storeIdsArray = computed<string[]>(() => {
    if (!currentStore.value) {
      return [];
    }

    if (Array.isArray(currentStore.value)) {
      return (currentStore.value as Brand[]).map(store => store.id);
    }

    return [currentStore.value?.id as string];
  });

  const merchantStores = computed(() => {
    if (Array.isArray(currentStore.value)) {
      return (currentStore.value as Brand[]).map(store => store.id);
    }

    return availableStores.value.filter(store => store.accountId === currentStore.value?.accountId) as any;
  });

  return {
    $bus,
    allActiveSelected,
    allDevSelected,
    allInactiveSelected,
    allSelectedCategory,
    allStores: availableStores,
    can,
    currentStore: selectedStore,
    currentStorePlatform,
    disable,
    enable,
    fetchContactSettings,
    findStoreById,
    loadStores,
    loading,
    merchantStores,
    multipleStoresSelected,
    numActiveStores,
    numDevStores,
    numInactiveStores,
    numOnboardingStores,
    selectedStoreIds,
    setCurrentStore,
    setStoreWithSlug,
    storeIdsArray,
    stores,
    updateContactSettings,
  };
});
