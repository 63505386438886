
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19,6H15a1,1,0,0,0,0,2h4a1,1,0,0,1,1,1v9.72l-1.57-1.45a1,1,0,0,0-.68-.27H9a1,1,0,0,1-1-1V15a1,1,0,0,0-2,0v1a3,3,0,0,0,3,3h8.36l3,2.73A1,1,0,0,0,21,22a1.1,1.1,0,0,0,.4-.08A1,1,0,0,0,22,21V9A3,3,0,0,0,19,6Zm-8.46,4.54A5,5,0,1,0,7,12,5,5,0,0,0,10.54,10.54ZM4,7A3,3,0,0,1,7,4a3,3,0,0,1,1.28.3l-4,4A3,3,0,0,1,4,7ZM9.7,5.71A3,3,0,0,1,10,7,3,3,0,0,1,5.72,9.7Z"/></svg>
  </template>

  <script>
  export default {
    name: 'LineCommentAltBlock',
    inheritAttrs: true,
  }
  </script>
