import { gql } from '@apollo/client/core';

export const createClaimMessage = gql`
  mutation CreateClaimMessage($input: CreateClaimMessageInput!) {
    createClaimMessage(input: $input) {
      id
      messageContent
      senderName
      senderType
      senderId
      readAt
      claimId
      createdAt
      updatedAt
      deletedAt
      medias {
        id
        claimMessageId
        fileKey
        caption
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const updateClaimState = gql`
  mutation UpdateClaimState($claimId: String!, $input: ClaimUpdateStateInput!) {
    updateClaimState(claimId: $claimId, state: $input) {
      id
      storeId
      orderId
      sourceOrderId
      authorName
      authorEmail
      claimDescription
      resolutionRequested
      resolutionDate
      claimState
      claimType
      isOta
      otaDescription
      externalSource
      policyId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const resolveClaim = gql`
  mutation ResolveClaim($claimId: String!, $input: ResolveClaimInput!) {
    resolveClaim(claimId: $claimId, state: $input) {
      id
      storeId
      orderId
      sourceOrderId
      authorName
      authorEmail
      claimDescription
      resolutionRequested
      resolutionDate
      claimState
      claimType
      isOta
      otaDescription
      externalSource
      policyId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const updateClaimItems = gql`
  mutation UpdateClaimItems($input: [ClaimItemUpdateInput!]!) {
    updateClaimItems(input: $input) {
      id
      name
      claimId
      orderId
      sourceOrderId
      resolutionQuantity
      resolutionPercentage
      resolutionId
      claimType
      resolutionRequested
      resolutionFinal
      sourceProductId
      sourceVariantId
      sku
      isAdditionalItem
      quantity
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const claimApproved = gql`
  mutation approveClaim($input: ResolvedClaimInput!) {
    approveClaim(input: $input)
  }
`;

export const removePolicy = gql`
  mutation policyRemove($policyId: String!, $sourceOrderId: String!, $platformId: String!) {
    policyRemove(policyId: $policyId, sourceOrderId: $sourceOrderId, platformId: $platformId) {
      id
    }
  }
`;
