import { gql } from '@apollo/client/core';
export const listWebhooks = gql`
  query getWebhooks($filter: WebhookFilter) {
    webhooks(filter: $filter) {
      edges {
        cursor
        node {
          id
          storeId
          topic
          url
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
