import { default as GatewayBase } from './gateway-base';

export default class MetadataService extends GatewayBase {
  getNotes(id: string) {
    return this.client.get(`v1/metadata/${id}/notes`);
  }

  createNote(
    id: string,
    input: { username: string; userId: string; threadId?: string; content: string; media?: any[] },
  ) {
    return this.client.post(`v1/metadata/${id}/notes`, input);
  }
}
