/** * this file was autogenerated by the icon-renderer module * the settings for this are in the nuxt.config.ts file *
if you would like to override the contents in this file you can create a custom component in the * assets/icons
directory and name it the same as the icon you would like to override * */
<template>
  <svg class="w-screen" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2 19.0002L18.6 16.6002M18.6 16.6002L21 14.2002M18.6 16.6002L16.2 14.2002M18.6 16.6002L21 19.0002M21 10.1944V9C21 8.64488 21 8.31227 20.9989 8M12.1992 19H9C8.24401 19 7.59006 19 7.01712 18.9893M20.9989 8H20C18.607 8 17.9104 8 17.324 8.06029C11.9031 8.61758 7.61758 12.9031 7.06029 18.324C7.03963 18.5249 7.02605 18.7388 7.01712 18.9893M20.9989 8C20.9912 5.84993 20.9309 4.66397 20.455 3.73005C19.9757 2.78924 19.2108 2.02433 18.27 1.54497C17.2004 1 15.8003 1 13 1H9C6.19974 1 4.79961 1 3.73005 1.54497C2.78924 2.02433 2.02433 2.78924 1.54497 3.73005C1 4.79961 1 6.19974 1 9V11C1 13.8003 1 15.2004 1.54497 16.27C2.02433 17.2108 2.78924 17.9757 3.73005 18.455C4.51086 18.8529 5.46784 18.9603 7.01712 18.9893M6.5 7.5C5.94772 7.5 5.5 7.05228 5.5 6.5C5.5 5.94772 5.94772 5.5 6.5 5.5C7.05228 5.5 7.5 5.94772 7.5 6.5C7.5 7.05228 7.05228 7.5 6.5 7.5Z"
      stroke="#1D2433"
      stroke-opacity="0.65"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImageCancel',
  inheritAttrs: true,
};
</script>
