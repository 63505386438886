
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99999 4.83333C8.77898 4.83333 8.56702 4.92113 8.41073 5.07741C8.25445 5.23369 8.16666 5.44565 8.16666 5.66667V9C8.16666 9.22101 8.25445 9.43298 8.41073 9.58926C8.56702 9.74554 8.77898 9.83333 8.99999 9.83333C9.221 9.83333 9.43297 9.74554 9.58925 9.58926C9.74553 9.43298 9.83332 9.22101 9.83332 9V5.66667C9.83332 5.44565 9.74553 5.23369 9.58925 5.07741C9.43297 4.92113 9.221 4.83333 8.99999 4.83333ZM8.99999 11.5C8.83517 11.5 8.67406 11.5489 8.53701 11.6404C8.39997 11.732 8.29316 11.8622 8.23009 12.0144C8.16702 12.1667 8.15051 12.3343 8.18267 12.4959C8.21482 12.6576 8.29419 12.806 8.41073 12.9226C8.52728 13.0391 8.67576 13.1185 8.83741 13.1507C8.99907 13.1828 9.16662 13.1663 9.31889 13.1032C9.47116 13.0402 9.60131 12.9334 9.69288 12.7963C9.78445 12.6593 9.83332 12.4982 9.83332 12.3333C9.83332 12.1123 9.74553 11.9004 9.58925 11.7441C9.43297 11.5878 9.221 11.5 8.99999 11.5ZM17.0917 5.3L12.7 0.908335C12.5403 0.756056 12.329 0.669738 12.1083 0.666668H5.89166C5.67102 0.669738 5.45968 0.756056 5.29999 0.908335L0.908323 5.3C0.756045 5.4597 0.669726 5.67103 0.666656 5.89167V12.1083C0.669726 12.329 0.756045 12.5403 0.908323 12.7L5.29999 17.0917C5.45968 17.2439 5.67102 17.3303 5.89166 17.3333H12.1083C12.329 17.3303 12.5403 17.2439 12.7 17.0917L17.0917 12.7C17.2439 12.5403 17.3303 12.329 17.3333 12.1083V5.89167C17.3303 5.67103 17.2439 5.4597 17.0917 5.3ZM15.6667 11.7583L11.7583 15.6667H6.24166L2.33332 11.7583V6.24167L6.24166 2.33333H11.7583L15.6667 6.24167V11.7583Z" fill="#96530F"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconWarning',
    inheritAttrs: true,
  }
  </script>
