import { can } from '~/composables/useEntitlements';
import { useUserStore } from '~/store/user';

export default defineNuxtRouteMiddleware(async to => {
  const app = useNuxtApp();
  const store = useUserStore(app.$pinia);

  // TODO fix error where you can't see anything but unauthorized 500 from gql
  return;

  if (!store.authenticated) return;
  if (!store.user?.entitlements) {
    await store.fetchUserInfo();
  }

  const Routes = {
    '/*/finance/invoices': [can(can.invoice.read)],
    '/*/finance/payouts': [can(can.payout.read)],
    '/*/orders': [can(can.order.read)],
    '/*/payouts': [can(can.payout.read)],
    '/*/settings/api-tokens': [can(can.apiTokens.read)],
    '/*/settings/billing': [can(can.billing.read)],
    '/*/settings/branding': [can(can.branding.read)],
    '/*/settings/contact': [can(can.contact.read)],
    '/*/settings/general': [can(can.settingsGeneral.read)],
    '/*/settings/webhooks': [can(can.webhook.read)],
    '/*/team-members': [can(can.teamMember.read)],
    '/*/tests': [can(can.abTests.read)],
    '/admin/*': [can(can.adminStores.read), can(can.adminUsers.read), can(can.whitelabel.list)],
    '/claims/*': [can(can.claim.read)],
    '/dashboard': [can(can.analytics.read)],
  } as Record<string, boolean[]>;

  if (!store.user) return;

  const route = Object.keys(Routes).find(route => new RegExp(`${route}`).test(to.path));

  if (!route) return; // No permissions required

  const permissions = Routes[route];

  if (!permissions || !permissions.length) return; // No permissions required

  const hasPermission = permissions.some(permission => !!permission);

  if (!hasPermission) {
    return navigateTo('/unauthorized');
  }

  return;
});
