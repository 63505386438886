import { gql } from '@apollo/client/core';

export const generalSettings = gql`
  query GeneralSettings($slug: String!) {
    generalSettings(slug: $slug) {
      id
      whenToFulfill
      enableCancelAfterPurchase
      sendConfirmationEmail
      enableOneTimeAppeasements
      maximumRefundAmount
      otaLimit
      refundToOriginalPaymentMethod
      reshipToCustomer
      replacementOrderCost
    }
  }
`;

export const shopifyMetadata = gql`
  query shopifyMetadata {
    shopifyMetadata {
      currency
    }
  }
`;

export const settingsAndPriceRules = gql`
  query GeneralSettingsPage($slug: String!) {
    storeSetting(slug: $slug) {
      storeLogoUrl
    }
    plan {
      revSharePercentage
      billingCycle
    }
    generalSettings(slug: $slug) {
      id
      whenToFulfill
      enableCancelAfterPurchase
      sendConfirmationEmail
      enableOneTimeAppeasements
      maximumRefundAmount
      otaLimit
      refundToOriginalPaymentMethod
      reshipToCustomer
      replacementOrderCost
      storeCreditType
      storeCreditBonus
      storeCreditBonusType
      waitingPeriodDelivered
      waitingPeriodInTransitUS
      waitingPeriodInTransitIntl
      inventoryManagement
    }
  }
`;

export const brandingSettings = gql`
  query WhitelabelSettings($slug: String!) {
    whitelabel(slug: $slug) {
      id
      brand100
      brand700
      brand800
      brand900
      buttonStyle
      backgroundColor
      backgroundImage
      conciergeBrandingEnabled
      customDomain
      logoUrl
      logoWidthMobile
      logoWidthDesktop
      fontWoff
      fontWoff2
      desktopHeadlineFontSize
      mobileHeadlineFontSize
      desktopHeadlineLineHeight
      mobileHeadlineLineHeight
      desktopHeadlineLetterSpacing
      mobileHeadlineLetterSpacing
      headlineTextTransform
      pageTitle
      faviconUrl
    }
  }
`;

export const capabilities = gql`
  query Capabilities($storeId: String!) {
    capabilities(storeId: $storeId) {
      refund
      reship
      credit
      whitelabel
      inventory
      splitTest
    }
  }
`;

export const getPlatformProductsByName = gql`
  query platformProductsByName($storeId: String!, $name: String!) {
    platformProductsByName(storeId: $storeId, name: $name) {
      id
      productId
      title
      displayName
      price
      image
      sku
      selectedOptions
      inventoryQuantity
      currency
    }
  }
`;
