import { gql } from '@apollo/client/core';

export const createABTest = gql`
  mutation createTest($input: CreateTestInput!) {
    createTest(input: $input) {
      id
    }
  }
`;

export const startABTest = gql`
  mutation startTest($id: String!) {
    startTest(id: $id) {
      id
    }
  }
`;

export const endABTest = gql`
  mutation endTest($id: String!) {
    endTest(id: $id) {
      id
    }
  }
`;

export const updateABTest = gql`
  mutation updateTest($id: String!, $input: UpdateTestInput!) {
    updateTest(id: $id, input: $input) {
      id
    }
  }
`;

export const duplicateABTest = gql`
  mutation duplicateTest($id: String!) {
    duplicateTest(id: $id) {
      id
    }
  }
`;
