import { catchError, lastValueFrom, of, tap } from 'rxjs';

import { useApplicationStore } from '~/store/application';
import { useUserStore } from '~/store/user';

export default defineNuxtPlugin(async () => {
  const store = useApplicationStore();
  const userStore = useUserStore();

  await lastValueFrom(
    store.load().pipe(
      catchError(() => {
        return of({});
      }),
      tap(() => {
        userStore.loading = false;
        store.loading = false;
        store.ready = true;
      }),
    ),
  );
});
