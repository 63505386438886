import type { PaginationOptions } from '@shared/interfaces';
import { map } from 'rxjs';

import GatewayBase from './gateway-base';

export type Webhook = {
  topic: string;
  secret?: string;
  url: string;
  storeId: string;
};

export type WebhookAfterCreate = Omit<Webhook, 'secret'> & {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

export default class DispatchService extends GatewayBase {
  get webhooks() {
    return {
      create: this.createWebhook.bind(this),
      delete: this.deleteWebhook.bind(this),
      fetch: this.fetchWebhook.bind(this),
      list: this.fetchWebhooks.bind(this),
      update: this.updateWebhook.bind(this),
    };
  }

  get slack() {
    return {
      channels: {
        list: this.fetchSlackChannels.bind(this),
      },
      config: {
        get: this.getConfig.bind(this),
        update: this.updateConfig.bind(this),
      },
    };
  }

  /**
   * Webhooks
   */

  // TODO: Share input type from backend
  private createWebhook(body: Omit<Webhook, 'createdAt' | 'updatedAt' | 'deletedAt'>) {
    return this.client.post<WebhookAfterCreate>(`/v1/webhooks`, body);
  }

  private updateWebhook(id: string, body: Partial<Webhook>) {
    return this.client.put<Webhook>(`/v1/webhooks/${id}`, body);
  }

  private deleteWebhook(id: string) {
    return this.client.delete(`/v1/webhooks/${id}`);
  }

  private fetchWebhook(id: string) {
    return this.client.get<WebhookAfterCreate>(`/v1/webhooks/${id}`);
  }

  private fetchWebhooks(paginationArgs: PaginationOptions<any>) {
    return this.client.get<{ edges: { node: WebhookAfterCreate }[] }>(`/v1/webhooks`, { params: paginationArgs });
  }

  /**
   * Slack
   */

  private fetchSlackChannels(cursor?: string) {
    return this.client.get<{
      ok: boolean;
      response_metadata: { next_cursor: string };
      channels: { id: string; name: string }[];
    }>(`/v1/slack/channels`, {
      params: { cursor },
    });
  }

  private getConfig(storeId: string) {
    return this.client.get<{ channelId: string; enabled: boolean }>(`/v1/slack/${storeId}/config`);
  }

  private updateConfig(input: { storeId: string; channelId: string; enabled: boolean }) {
    return this.client.put(`/v1/slack/${input.storeId}/config`, input);
  }
}
