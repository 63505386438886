export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('hubble', {
    mounted(el: HTMLElement, binding: { value: string }) {
      const val = binding.value;
      if (process.env.NODE_ENV === 'test') {
        el.setAttribute('data-hubble', val);
      }
    },
  });
});
