import emitter from 'mitt';

export default function createEmitter() {
  const mitt = emitter();

  return {
    emit: (event: string, data: any) => mitt.emit(event, data),
    off: (event: string, callback: any) => mitt.off(event, callback),
    on: (event: string, callback: any) => mitt.on(event, callback),
  };
}
