
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6569 5.24323C11.4819 4.89323 11.1902 4.65989 10.8985 4.48489L2.73186 0.401559C1.85686 -0.00677454 0.806859 0.343225 0.398526 1.15989C0.106859 1.62656 0.106859 2.15156 0.340192 2.61823L1.56519 5.41823H6.87353C7.22353 5.41823 7.45686 5.65156 7.45686 6.00156C7.45686 6.35156 7.22353 6.58489 6.87353 6.58489H1.56519L0.340192 9.38489C-0.0681411 10.2599 0.340192 11.3099 1.21519 11.7182C1.44853 11.7766 1.68186 11.8349 1.91519 11.8349C2.20686 11.8349 2.44019 11.7766 2.67353 11.6599L10.8402 7.57656C11.7152 7.10989 12.0652 6.05989 11.6569 5.24323Z" fill="white"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconSend',
    inheritAttrs: true,
  }
  </script>
