import revive_payload_client_2JCs1ROTuy from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6MmvcmgH8b from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7Yuhn1iV4D from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_2OZOeGd8fN from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GA6aJG6AVb from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_I0mq4GVLxk from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_n5DXlAQlLb from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.21.2_typescript@5.0.4_vue@3.5.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/valet/.nuxt/components.plugin.mjs";
import prefetch_client_r7dYsShOLS from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.16.18_eslint@8.57.0_less@4.1.3_rollup@4.21.2_wklvvwbon6bb3pll7kjpsf2wbu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/app/apps/valet/.nuxt/floating-vue.mjs";
import vue_easy_lightbox_FiXRaiZhUt from "/app/apps/valet/modules/runtime/vue-easy-lightbox.ts";
import apollo_1ILFkzpYSr from "/app/apps/valet/plugins/apollo.ts";
import can_Y4WXuotLdU from "/app/apps/valet/plugins/can.ts";
import dompurify_html_VcfsMfUvBB from "/app/apps/valet/plugins/dompurify-html.ts";
import hubble_BkAayVL0mp from "/app/apps/valet/plugins/hubble.ts";
import initialize_0ZT37Pxz4i from "/app/apps/valet/plugins/initialize.ts";
import language_client_zoJjvNpxrl from "/app/apps/valet/plugins/language.client.ts";
import realtime_RCCOj7Gh8E from "/app/apps/valet/plugins/realtime.ts";
import sentry_3AyO8nEfhE from "/app/apps/valet/plugins/sentry.ts";
export default [
  revive_payload_client_2JCs1ROTuy,
  unhead_6MmvcmgH8b,
  router_7Yuhn1iV4D,
  payload_client_2OZOeGd8fN,
  navigation_repaint_client_GA6aJG6AVb,
  chunk_reload_client_I0mq4GVLxk,
  plugin_vue3_n5DXlAQlLb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_r7dYsShOLS,
  floating_vue_EIcJ7xiw0h,
  vue_easy_lightbox_FiXRaiZhUt,
  apollo_1ILFkzpYSr,
  can_Y4WXuotLdU,
  dompurify_html_VcfsMfUvBB,
  hubble_BkAayVL0mp,
  initialize_0ZT37Pxz4i,
  language_client_zoJjvNpxrl,
  realtime_RCCOj7Gh8E,
  sentry_3AyO8nEfhE
]