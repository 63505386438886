import ApiBase from './base';

export default class ClaimService extends ApiBase {
  async exportClaims(body: unknown) {
    const { data } = await this.client.post(`v1/claims/export-claims`, body);

    return data;
  }

  async exportStoreCredits(body: unknown) {
    const { data } = await this.client.post(`v1/credits/export`, body);

    return data;
  }
}
