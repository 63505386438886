import { defineStore } from 'pinia';

import { OrderService } from '~/support/services';
import createBus from '~/support/utils/event-bus';

export const useOrdersStore = defineStore('orders', () => {
  const endDate = new Date();
  const startDate = new Date(new Date().setDate(endDate.getDate() - 7));
  const dateRange = ref([startDate, endDate]);
  const rowsPerPage = ref(10);
  const currentPage = ref(1);

  async function exportOrders(body: {
    storeIds: string[];
    filter: { createdAtMax: Date; createdAtMin: Date };
    timezone?: string;
  }) {
    const service = new OrderService(useRuntimeConfig().public.apiBaseUrl);

    return service.exportOrders(body);
  }

  const $bus = createBus();

  function setDateRange(range: any) {
    dateRange.value = range;
  }

  function setRowsPerPage(rows: number) {
    rowsPerPage.value = rows;
  }

  function setCurrentPage(page: number) {
    currentPage.value = page;
  }

  return {
    $bus,
    currentPage,
    dateRange,
    exportOrders,
    rowsPerPage,
    setCurrentPage,
    setDateRange,
    setRowsPerPage,
  };
});
