import { gql } from '@apollo/client/core';

export const createWebhook = gql`
  mutation ($input: WebhookInput!) {
    createWebhook(input: $input) {
      id
      storeId
      topic
      url
      secret
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const deleteWebhook = gql`
  mutation ($webhookId: String!) {
    deleteWebhook(webhookId: $webhookId)
  }
`;
