import { gql } from '@apollo/client/core';

export const getTests = gql`
  query tests($storeId: String!) {
    tests(storeId: $storeId) {
      id
      name
      storeId
      percentOfTraffic
      type
      startDate
      endDate
      state
      results {
        overall {
          settingsType
          isOriginal
          conversionRate
          impressions
          purchases
          purchasedOpTrue
          attachRate
          cvrCompareToOriginal
          attachRateCompareToOriginal
          profitCompareToOriginal
          color {
            light
            dark
          }
        }
      }
      daysOfTesting
      createdAt
      deletedAt
      testPriceRuleSets {
        id
      }
    }
  }
`;

export const getTestById = gql`
  query test($id: String!) {
    test(id: $id) {
      id
      name
      storeId
      percentOfTraffic
      type
      startDate
      endDate
      state
      results {
        overall {
          settingsType
          isOriginal
          conversionRate
          impressions
          purchases
          purchasedOpTrue
          attachRate
          profit
          cvrCompareToOriginal
          attachRateCompareToOriginal
          profitCompareToOriginal
          color {
            light
            dark
          }
        }
        byDay {
          settingsType
          conversionRate
          attachRate
          profit
          date
        }
      }
      daysOfTesting
      createdAt
      deletedAt
      testPriceRuleSets {
        id
        testId
        title
        isOriginal
        createdAt
        priceRuleSet {
          id
          automaticallyAddToCart
          mandatoryOp
          rules {
            id
            min
            max
            terms {
              paidBy
              operator
              brand {
                amount
                type
              }
              customer {
                amount
                type
              }
            }
          }
        }
      }
    }
  }
`;
