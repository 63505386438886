import GatewayBase from './gateway-base';
import type { PaginatedResponse } from '../../../../libs/shared/types';

export class MerchantRestService extends GatewayBase {
  get stores() {
    return {
      admin: {
        list: this.getAdminStores.bind(this),
      },
      count: this.getStoreCounts.bind(this),
      list: this.getStores.bind(this),
    };
  }

  private getStores(params: object = {}) {
    return this.client.get('/v1/stores', { params });
  }

  private getAdminStores(params: object = {}) {
    return this.client.get<
      PaginatedResponse<{
        capabilities: object;
        generalSettings: object;
        storeSettings: object;
        contactSettings: object;
        id: string;
        name: string;
        slug: string;
        accountId: string;
        platformId: string;
        storeDomain: string;
      }>
    >('/v1/admin/stores', { params });
  }

  get pricing() {
    return {
      rules: this.getRules.bind(this),
      update: this.updatePriceRuleSet.bind(this),
      variant: this.getClosestVariant.bind(this),
    };
  }

  getClosestVariant(storeId: string, subtotal: number) {
    return this.client.get(`v1/quotes/${storeId}/variant?subtotal=${subtotal}`);
  }

  getRules(storeId: string) {
    return this.client.get<{
      id: string;
      automaticallyAddToCart: boolean;
      mandatoryOp: boolean;
      rules: {
        id: string;
        min: number;
        max: number;
        terms: {
          customer: {
            amount: number | string;
            type: string;
          };
          brand: {
            amount: number | string;
            type: string;
          };
          operator: string;
          paidBy: string;
        };
      }[];
    }>(`v1/quotes/${storeId}/rules`);
  }

  getStoreCounts() {
    return this.client.get<{
      active: number;
      inactive: number;
      onboarding: number;
      dev: number;
    }>('/v1/stores/counts');
  }

  private updatePriceRuleSet(
    id: string,
    input: {
      storeId: string;
      payload: {
        rules?: {
          brandCalcMethod?: string;
          brandPrice?: number;
          customerCalcMethod?: string;
          customerPrice?: number;
          hybridOperator?: string;
          maxValue: number;
          minValue: number;
        }[];
        automaticallyAddToCart: boolean;
        mandatoryOp: boolean;
      };
    },
  ) {
    return this.client.put(`v1/quotes/${input.storeId}/rules/${id}`, input.payload);
  }
}
