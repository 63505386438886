import { defineStore } from 'pinia';

import { ClaimService } from '~/support/services';
import createBus from '~/support/utils/event-bus';

import { useUserStore } from './user';

export const useClaimStore = defineStore('claims', () => {
  const userStore = useUserStore();
  const { account } = storeToRefs(userStore);
  async function exportClaims(body: { storeIds: string[]; filter: { claimState: string }; timezone?: string }) {
    const service = new ClaimService(useRuntimeConfig().public.apiBaseUrl);

    return service.exportClaims(body);
  }

  async function exportStoreCredits(body: { storeIds: string[]; filter: { claimState: string }; timezone?: string }) {
    const service = new ClaimService(useRuntimeConfig().public.apiBaseUrl);

    return service.exportStoreCredits(body);
  }

  const $bus = createBus();

  return {
    $bus,
    exportClaims,
    exportStoreCredits,
  };
});
