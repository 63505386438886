import { request } from '~/support/utils';

export default class ApiBase {
  #baseUrl;

  constructor(baseUrl: string) {
    this.#baseUrl = baseUrl;
  }

  get client() {
    return request.create({
      baseURL: this.#baseUrl,
    });
  }
}
