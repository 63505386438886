
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9763_27013)">
<path d="M19.6253 6.04369C19.6104 5.93764 19.5155 5.8788 19.4367 5.8724C19.3586 5.86599 17.8273 5.84328 17.8273 5.84328C17.8273 5.84328 16.5467 4.62911 16.4202 4.5056C16.2937 4.38209 16.0466 4.41937 15.9505 4.44734C15.9494 4.44792 15.7101 4.52016 15.3073 4.64193C15.2398 4.4287 15.1407 4.16652 14.9993 3.90318C14.5434 3.05373 13.8757 2.60454 13.0689 2.60338C13.0677 2.60338 13.0671 2.60338 13.0659 2.60338C13.0098 2.60338 12.9543 2.60862 12.8982 2.61328C12.8743 2.58531 12.8505 2.55793 12.8254 2.53113C12.4739 2.16409 12.0234 1.98522 11.4833 2.00095C10.4414 2.03008 9.40368 2.76476 8.56227 4.06981C7.97029 4.988 7.51974 6.14158 7.39205 7.03472C6.19557 7.39651 5.35893 7.64935 5.34043 7.65519C4.73652 7.84045 4.71743 7.85851 4.63866 8.41434C4.58018 8.83438 3 20.764 3 20.764L16.2424 23L21.9818 21.607C21.9818 21.607 19.6402 6.14974 19.6253 6.04369ZM14.6443 4.84235C14.3393 4.9344 13.9926 5.03927 13.6167 5.15288C13.6089 4.63785 13.5463 3.92124 13.3004 3.30192C14.0911 3.44816 14.4802 4.32149 14.6443 4.84235ZM12.9238 5.36262C12.2298 5.57235 11.4726 5.80131 10.7129 6.03088C10.9265 5.23211 11.3318 4.43685 11.8294 3.91541C12.0144 3.7214 12.2734 3.50526 12.5801 3.38174C12.8683 3.96901 12.931 4.8004 12.9238 5.36262ZM11.5042 2.67795C11.7489 2.6727 11.9547 2.72514 12.1308 2.83817C11.8491 2.98091 11.577 3.18599 11.3216 3.4534C10.6598 4.14671 10.1526 5.22279 9.95027 6.261C9.31951 6.45151 8.70247 6.63855 8.13437 6.80984C8.49304 5.1756 9.89596 2.72339 11.5042 2.67795Z" fill="#95BF47"/>
<path d="M19.4366 5.87289C19.3584 5.86648 17.8271 5.84377 17.8271 5.84377C17.8271 5.84377 16.5465 4.6296 16.42 4.50609C16.3729 4.46006 16.309 4.43617 16.2422 4.42627L16.2428 22.9993L21.9817 21.6069C21.9817 21.6069 19.6401 6.15023 19.6252 6.04418C19.6102 5.93813 19.5147 5.87929 19.4366 5.87289Z" fill="#5E8E3E"/>
<path d="M13.0611 8.754L12.3945 11.1887C12.3945 11.1887 11.651 10.8584 10.7696 10.9126C9.47704 10.9924 9.46331 11.7882 9.47647 11.9881C9.54688 13.077 12.4811 13.3147 12.6458 15.8653C12.7753 17.8719 11.5555 19.2445 9.79811 19.3528C7.68861 19.4828 6.52734 18.268 6.52734 18.268L6.97431 16.4118C6.97431 16.4118 8.14332 17.2729 9.07901 17.2152C9.69009 17.1774 9.90849 16.6921 9.8864 16.3489C9.79452 14.9285 7.40515 15.0124 7.25419 12.6784C7.12707 10.7145 8.44828 8.72429 11.3633 8.54484C12.4864 8.47435 13.0611 8.754 13.0611 8.754Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_9763_27013">
<rect width="19" height="21" fill="white" transform="translate(3 2)"/>
</clipPath>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'ShopifyBag',
    inheritAttrs: true,
  }
  </script>
