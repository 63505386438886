import { gql } from '@apollo/client/core';
export const createBillingMethod = gql`
  mutation billingMethodCreate($input: BillingMethodCreateInput!) {
    billingMethodCreate(input: $input) {
      id
      name
      creditCard
      cardType
      routingNumber
      accountNumber
      voidedAt
      billingAddress {
        address
        city
        state
        zip
        country
      }
    }
  }
`;

export const updateBillingMethods = gql`
  mutation billingMethodsUpdate($input: [BillingMethodsUpdateInput!]!) {
    billingMethodsUpdate(input: $input)
  }
`;

export const deleteMethod = gql`
  mutation billingMethodDelete($input: String!) {
    billingMethodDelete(input: $input) {
      name
    }
  }
`;

export const setDefaultMethod = gql`
  mutation billingMethodSetDefault($input: String!) {
    billingMethodSetDefault(input: $input) {
      name
      creditCard
      voidedAt
    }
  }
`;

export const chargeInvoices = gql`
  mutation invoicesCharge($ids: [String!]!, $storeId: String!) {
    invoicesCharge(ids: $ids, storeId: $storeId)
  }
`;

export const updateInvoiceStatuses = gql`
  mutation invoicesStatusUpdate($ids: [String!]!, $status: String!) {
    invoicesStatusUpdate(ids: $ids, status: $status)
  }
`;

export const updatePayoutStatus = gql`
  mutation payoutStatusUpdate($id: String!, $status: PayoutStatus!) {
    payoutStatusUpdate(id: $id, status: $status)
  }
`;

export const updatePlan = gql`
  mutation planUpdate($revenuePercentage: Float!, $billingCycle: BillingCycle!) {
    planUpdate(revenuePercentage: $revenuePercentage, billingCycle: $billingCycle) {
      revSharePercentage
      billingCycle
    }
  }
`;

export const updateBillingSettings = gql`
  mutation billingSettingsUpdate($billingCycle: BillingCycle!) {
    billingSettingsUpdate(billingCycle: $billingCycle) {
      billingCycle
    }
  }
`;
