import { gql } from '@apollo/client/core';

export const authTokens = gql`
  query GetAuthTokens {
    authTokens {
      id
      name
      createdAt
    }
  }
`;
