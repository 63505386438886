
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99999 0.666672C4.41666 0.666672 0.666656 4.41667 0.666656 9.00001C0.666656 13.5833 4.41666 17.3333 8.99999 17.3333C13.5833 17.3333 17.3333 13.5833 17.3333 9.00001C17.3333 4.41667 13.5833 0.666672 8.99999 0.666672ZM12.0833 10.9167C12.4167 11.25 12.4167 11.75 12.0833 12.0833C11.75 12.4167 11.25 12.4167 10.9167 12.0833L8.99999 10.1667L7.08332 12.0833C6.74999 12.4167 6.24999 12.4167 5.91666 12.0833C5.58332 11.75 5.58332 11.25 5.91666 10.9167L7.83332 9.00001L5.91666 7.08334C5.58332 6.75001 5.58332 6.25001 5.91666 5.91667C6.24999 5.58334 6.74999 5.58334 7.08332 5.91667L8.99999 7.83334L10.9167 5.91667C11.25 5.58334 11.75 5.58334 12.0833 5.91667C12.4167 6.25001 12.4167 6.75001 12.0833 7.08334L10.1667 9.00001L12.0833 10.9167Z" fill="#BA2532"/>
</svg>

  </template>

  <script>
  export default {
    name: 'IconDanger',
    inheritAttrs: true,
  }
  </script>
