import { gql } from '@apollo/client/core';

export const adminStoreCounts = gql`
  query adminStoreCounts {
    adminStoreCounts {
      active
      inactive
      dev
      onboarding
    }
  }
`;

export const listAdminStores = gql`
  query adminStores($filter: StoreTypeFilter, $first: Int, $after: String, $last: Int, $before: String) {
    adminStores(filter: $filter, first: $first, after: $after, last: $last, before: $before) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          id
          name
          slug
          accountId
          platformId
          storeType
          storeUrl
          storeDomain
          settings {
            storeLogoUrl
          }
          generalSettings {
            enableOneTimeAppeasements
            replacementOrderCost
            storeCreditType
            storeCreditBonus
            storeCreditBonusType
          }
          contactSettings {
            billingEmail
          }
          capabilities {
            whitelabel
            credit
            refund
            reship
            splitTest
            inventory
          }
        }
        cursor
      }
    }
  }
`;
