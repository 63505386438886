import { gql } from '@apollo/client/core';

export const authTokenCreate = gql`
  mutation authTokenCreate($name: String!) {
    authTokenCreate(name: $name) {
      name
      token
    }
  }
`;

export const authTokenDelete = gql`
  mutation authTokenDelete($id: String!) {
    authTokenDelete(id: $id)
  }
`;
