import { gql } from '@apollo/client/core';

export const accountCreate = gql`
  mutation accountCreate($input: AccountCreateInput!) {
    accountCreate(input: $input) {
      id
      name
    }
  }
`;
