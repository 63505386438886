
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9966 2.63721L3.88281 7.32068V16.6754L6.199 18.0144L6.18264 8.65765L11.9803 5.31114L17.7779 8.65765V18.0103L20.0941 16.6754V7.3125L11.9966 2.63721Z" fill="#F26322"/>
<path d="M13.1461 18.0124L11.9891 18.685L10.8259 18.0185V8.65771L8.51172 9.99468L8.51581 19.3514L11.987 21.3569L15.4623 19.3514V9.99468L13.1461 8.65771V18.0124Z" fill="#F26322"/>
</svg>

  </template>

  <script>
  export default {
    name: 'MagentoLogo',
    inheritAttrs: true,
  }
  </script>
