export class ValidationError extends Error {
  constructor(private errors: { field: string; error: string }[]) {
    super('Bad Request');
    this.name = 'Bad Request';
  }

  override toString() {
    return this.errors.map(error => error.error).join(', ');
  }

  fieldError(field: string) {
    return this.errors.find(error => error.field === field)?.error;
  }

  hasError(field: string) {
    return !!this.fieldError(field);
  }

  get fieldErrors() {
    return this.errors;
  }
}

export class UnauthorizedException extends Error {
  constructor(private code: string) {
    super('Unauthorized');
    this.name = 'Unauthorized';
  }

  getCode() {
    return this.code;
  }
}

export class UnableToRefreshToken extends Error {
  constructor() {
    super('Unable to refresh token');
    this.name = UnableToRefreshToken.name;
  }
}
