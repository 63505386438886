import { gql } from '@apollo/client/core';

export const getValidationStatus = gql`
  query getValidationStatus {
    validationStatus {
      status
      domain
      storeName
      recordName
      recordValue
      contactEmail
      loadBalancerAddress
      storeName
    }
  }
`;
