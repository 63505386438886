export const WebsocketEvents = {
  Claim: {
    Created: 'claim.created',
    Updated: 'claim.updated',
    StateUpdated: 'claim.state.updated',
  },
  Order: {
    Created: 'order.created',
    Reship: {
      Created: 'order.reship.created',
    },
    Note: {
      Created: 'order.note.created',
    },
  },
  OrderItem: {
    Created: 'order-item.created',
  },
  Message: {
    Created: 'message.created',
  },
  StoreCredit: {
    Created: 'store-credit.created',
  },
};
