import * as Ably from 'ably';
import { storeToRefs } from 'pinia';

import { useUserStore } from '~/store/user';

function clientId(user: any) {
  if (!user) return null;

  if ((!user.firstName || !user.lastName) && !user.nickname) {
    return null;
  }

  if (user.nickname) {
    return `${user.email}:${user.nickname}`;
  }

  return `${user.email}:${user.firstName} ${user.lastName}`;
}

export default function useRealtime() {
  const { user } = storeToRefs(useUserStore());
  const config = useRuntimeConfig();
  const ably = new Ably.Realtime({
    clientId: clientId(user.value) ?? user.value?.name ?? config.public.ably.clientId,
    key: config.public.ably.apiKey,
    logLevel: 1,
  });

  function subscribe(channel: string, event: string, callback: (message: Ably.Message) => void): void {
    const channelInstance = ably.channels.get(channel);
    channelInstance.subscribe(event, callback);
  }

  function unsubscribe(channel: string, event: string): void {
    const channelInstance = ably.channels.get(channel);
    channelInstance.unsubscribe(event);
  }

  return {
    instance: ably,
    subscribe,
    unsubscribe,
  };
}
