import { WebsocketEvents } from '@websockets';

import { useClaimStore } from '~/store/claims';
import { useOrdersStore } from '~/store/orders';
import { useToastStore } from '~/store/toast';
import { useUserStore } from '~/store/user';

type NotificationType = 'info' | 'success' | 'warning' | 'error';

export default defineNuxtPlugin(async () => {
  const orders = useOrdersStore();
  const user = useUserStore();
  const realtime = useRealtime();
  const claims = useClaimStore();
  const toasts = useToastStore();

  const { account } = storeToRefs(user);

  function createNotification(event: string, message: { data?: any }) {
    switch (event) {
      case WebsocketEvents.Message.Created:
        if (message.data.senderType !== 'customer') return;

        return {
          // having an id here should prevent duplicate notifications
          id: (message.data as any).claimId,
          message: `New message on claim #${message.data.claimId}.`,
          to: `/claims/view/${message.data.orderId}`,
          type: 'info' as NotificationType,
        };
      case WebsocketEvents.Claim.Created:
        return {
          id: (message.data as any).id,
          message: `New claim #${message.data.id} has been opened by ${message.data.authorName}.`,
          to: `/claims/view/${message.data.orderId}`,
          type: 'info' as NotificationType,
        };
      default:
        return null;
    }
  }

  function subscribe(value: string) {
    for (const event of [WebsocketEvents.Order.Created]) {
      realtime.subscribe(value, event, async message => {
        orders.$bus.emit(event, message.data);
      });
    }

    for (const event of [
      WebsocketEvents.Message.Created,
      WebsocketEvents.Claim.Created,
      WebsocketEvents.Claim.Updated,
      WebsocketEvents.Claim.StateUpdated,
      WebsocketEvents.Order.Reship.Created,
      WebsocketEvents.OrderItem.Created,
    ]) {
      realtime.subscribe(value, event, async message => {
        const notification = createNotification(event, message);

        if (notification) {
          toasts.addToast(notification);
        }

        claims.$bus.emit(event, message.data);
      });
    }
  }

  onMounted(() => {
    if (account.value) {
      subscribe(account.value);
    }
  });

  watch(account, async value => {
    if (value) {
      subscribe(value);
    }
  });
});
