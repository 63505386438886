import { gql } from '@apollo/client/core';

export const getMacrosByStoreId = gql`
  query MacrosByStoreId($storeId: String!) {
    macrosByStoreId(storeId: $storeId) {
      id
      title
      folder
      content
      default
      stores {
        macroId
        storeId
        global
        id
      }
    }
  }
`;
