import { gql } from '@apollo/client/core';

export const activateStore = gql`
  mutation ActivateStore($id: ID!) {
    storeActivate(id: $id) {
      storeType
      activatedAt
    }
  }
`;

export const generalSettingsWidgetUpdate = gql`
  mutation generalSettingsWidgetUpdate($settingsId: String!, $input: UpdateWidgetSettingsInput!) {
    generalSettingsWidgetUpdate(settingsId: $settingsId, input: $input) {
      enableCancelAfterPurchase
    }
  }
`;

export const generalSettingsStoreCreditUpdate = gql`
  mutation generalSettingsStoreCreditUpdate($settingsId: String!, $input: UpdateStoreCreditSettingsInput!) {
    generalSettingsStoreCreditUpdate(settingsId: $settingsId, input: $input) {
      storeCreditType
      storeCreditBonus
      storeCreditBonusType
    }
  }
`;

export const generalSettingsAdminFulfillmentUpdate = gql`
  mutation generalSettingsAdminFulfillmentUpdate($settingsId: String!, $input: UpdateAdminFulfillmentInput!) {
    generalSettingsAdminFulfillmentUpdate(settingsId: $settingsId, input: $input) {
      waitingPeriodDelivered
      waitingPeriodInTransitUS
      waitingPeriodInTransitIntl
    }
  }
`;

export const generalSettingsCommunicationUpdate = gql`
  mutation generalSettingsCommunicationUpdate($settingsId: String!, $input: UpdateCommunicationSettingsInput!) {
    generalSettingsCommunicationUpdate(settingsId: $settingsId, input: $input) {
      sendConfirmationEmail
    }
  }
`;

export const generalSettingsReplacementSettings = gql`
  mutation generalSettingsReplacementSettings($settingsId: String!, $input: UpdateReplacementSettingsInput!) {
    generalSettingsReplacementSettingsUpdate(settingsId: $settingsId, input: $input) {
      replacementOrderCost
    }
  }
`;

export const generalSettingsInventorySettings = gql`
  mutation generalSettingsInventorySettings($settingsId: String!, $input: UpdateInventorySettingsInput!) {
    generalSettingsInventorySettingsUpdate(settingsId: $settingsId, input: $input) {
      inventoryManagement
    }
  }
`;

export const generalSettingsFulfillmentUpdate = gql`
  mutation generalSettingsFulfillmentUpdate($settingsId: String!, $input: UpdateFulfillmentSettingsInput!) {
    generalSettingsFulfillmentUpdate(settingsId: $settingsId, input: $input) {
      whenToFulfill
    }
  }
`;

export const generalSettingsOneTimeAppeasementSettings = gql`
  mutation generalSettingsOneTimeAppeasementSettings(
    $settingsId: String!
    $input: UpdateOneTimeAppeasementSettingsInput!
  ) {
    generalSettingsOneTimeAppeasementSettingsUpdate(settingsId: $settingsId, input: $input) {
      enableOneTimeAppeasements
      maximumRefundAmount
      otaLimit
      refundToOriginalPaymentMethod
      reshipToCustomer
    }
  }
`;

export const priceRuleSetUpdate = gql`
  mutation UpdatePriceRuleSettings($settingsId: String!, $input: UpdateRulesetInput!) {
    priceRuleSetUpdate(settingsId: $settingsId, input: $input) {
      rules {
        id
        min
        max
        terms {
          customer {
            amount
            type
          }
          brand {
            amount
            type
          }
          operator
          paidBy
        }
      }
    }
  }
`;

export const whitelabelCustomDomainUpdate = gql`
  mutation UpdateCustomDomain($settingId: String!, $input: UpdateCustomDomainDto!) {
    whitelabelCustomDomainUpdate(settingId: $settingId, input: $input) {
      customDomain
    }
  }
`;

export const whitelabelButtonStyleUpdate = gql`
  mutation UpdateButtonStyle($settingsId: String!, $input: UpdateWhiteLabelButtonStyleDto!) {
    whitelabelButtonStyleUpdate(settingsId: $settingsId, input: $input) {
      id
      brand100
      brand700
      storeId
      conciergeBrandingEnabled
      brand800
      brand900
      buttonStyle
      backgroundImage
      backgroundColor
      logoUrl
      logoWidthMobile
      logoWidthDesktop
      fontWoff
      fontWoff2
      desktopHeadlineFontSize
      mobileHeadlineFontSize
      desktopHeadlineLineHeight
      mobileHeadlineLineHeight
      desktopHeadlineLetterSpacing
      mobileHeadlineLetterSpacing
      headlineTextTransform
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const whitelabelBackgroundStyleUpdate = gql`
  mutation UpdateBackgroundStyle($settingsId: String!, $input: UpdateWhiteLabelBackgroundDto!) {
    whitelabelBackgroundStyleUpdate(settingsId: $settingsId, input: $input) {
      backgroundImage
      backgroundColor
    }
  }
`;

export const whitelabelEnabledUpdate = gql`
  mutation UpdateWhitelabelEnabled($settingsId: String!, $input: UpdateWhitelabelEnabledStatusDto!) {
    whitelabelEnabledUpdate(settingsId: $settingsId, input: $input) {
      conciergeBrandingEnabled
    }
  }
`;

export const whitelabelColorsUpdate = gql`
  mutation UpdateWhitelabelColors($settingsId: String!, $input: UpdateWhiteLabelColorsDto!) {
    whitelabelColorsUpdate(settingsId: $settingsId, input: $input) {
      brand100
      brand700
      brand800
      brand900
    }
  }
`;

export const whitelabelLogoUpdate = gql`
  mutation UpdateWhitelabelLogo($settingsId: String!, $input: UpdateWhiteLabelLogoDto!) {
    whitelabelLogoUpdate(settingsId: $settingsId, input: $input) {
      logoUrl
      logoWidthMobile
      logoWidthDesktop
    }
  }
`;

export const whitelabelMetaTagUpdate = gql`
  mutation UpdateMetaTags($settingsId: String!, $input: UpdateMetaTagDto!) {
    whitelabelMetaTagUpdate(settingsId: $settingsId, input: $input) {
      pageTitle
      faviconUrl
    }
  }
`;

export const whitelabelFontUpdate = gql`
  mutation UpdateWhitelabelFontSettings($settingsId: String!, $input: UpdateWhitelabelFontSettingsDto!) {
    whitelabelFontUpdate(settingsId: $settingsId, input: $input) {
      fontWoff
      fontWoff2
      desktopHeadlineFontSize
      mobileHeadlineFontSize
      desktopHeadlineLineHeight
      mobileHeadlineLineHeight
      desktopHeadlineLetterSpacing
      mobileHeadlineLetterSpacing
      headlineTextTransform
    }
  }
`;

export const updateStoreSettings = gql`
  mutation UpdateStoreSettings($input: UpdateStoreSettingInput!) {
    updateStoreSetting(input: $input) {
      storeLogoUrl
      enableCoBranding
    }
  }
`;

export const updateCapabilities = gql`
  mutation UpdateCapabilities($storeId: String!, $input: CapabilitiesInput!) {
    capabilitiesUpdate(storeId: $storeId, input: $input) {
      refund
      credit
      reship
      whitelabel
      inventory
      splitTest
    }
  }
`;
