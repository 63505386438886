
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="8" fill="#5B1FD3"/>
<path d="M7.68626 8.68484L6.35563 7.67335L5.88281 8.25907L7.75212 9.70497L11.3857 5.19165L10.4753 5.125L7.68626 8.68484Z" fill="white"/>
<path d="M10.3804 7.56712C9.95309 9.03497 9.04745 10.2229 7.63606 11.1615C6.71159 10.6544 5.96107 9.88098 5.48212 8.94166C5.04616 8.09326 4.78583 7.04883 4.70585 5.82876C5.71725 5.69101 6.71075 5.44415 7.66899 5.09248C8.17396 5.28801 8.69033 5.45272 9.21525 5.58568L9.70845 4.9584C9.01261 4.80151 8.33117 4.58643 7.67134 4.31543C6.55086 4.76786 5.30569 5.05327 3.95703 5.19206C4.00016 8.29085 5.02812 10.7051 7.67134 11.9997C9.8049 10.7051 11.0924 8.86795 11.3433 6.3643L10.3804 7.56712Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_7355_93958" x1="0.666667" y1="8" x2="16" y2="8" gradientUnits="userSpaceOnUse">
<stop stop-color="#5B1FD3"/>
<stop offset="1" stop-color="#44179D"/>
</linearGradient>
</defs>
</svg>

  </template>

  <script>
  export default {
    name: 'CircleLogo',
    inheritAttrs: true,
  }
  </script>
