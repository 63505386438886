import { defineStore } from 'pinia';
import { v4 } from 'uuid';

type Toast = {
  timeout?: number;
  id?: string;
  title?: string;
  message?: string;
  to?: string;
  type: 'success' | 'error' | 'warning' | 'info';
};

export const useToastStore = defineStore('toast', () => {
  const state = reactive({
    toasts: [] as Toast[],
  });

  function addToast(toast: Toast) {
    // do not add the same toast twice
    if (state.toasts.find(t => t.id === toast.id)) return;

    toast.id ||= v4();
    state.toasts.push(toast);

    if (toast.timeout) {
      setTimeout(() => {
        removeToast(toast.id as string);
      }, toast.timeout);
    }

    return toast.id;
  }

  function removeToast(id: string) {
    state.toasts.splice(
      state.toasts.findIndex(toast => toast.id === id),
      1,
    );
  }

  function clearAll() {
    state.toasts.forEach(toast => removeToast(toast.id as string));
  }

  return {
    addToast,
    clearAll,
    removeToast,
    toasts: readonly(state.toasts) as Toast[],
  };
});
