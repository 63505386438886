import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin(nuxtApp => {
  const { $config, vueApp } = nuxtApp;

  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      app: vueApp,
      dsn: $config.public.sentry.dsn,
      environment: $config.public.sentry.environment,
      integrations: [], // TODO: performance, session replay, tracing
    });
  }
});
