import { createHttpLink, from, InMemoryCache } from '@apollo/client/core';
import { ApolloClient } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { DefaultApolloClient, ApolloClients, provideApolloClients } from '@vue/apollo-composable';
import { createApolloProvider } from '@vue/apollo-option';

import { useMerchantStore } from '~/store/merchant';

/* eslint-disable @typescript-eslint/ban-ts-comment */

export default defineNuxtPlugin(nuxtApp => {
  const clients: { [key: string]: ApolloClient<any> } = {};
  const { apollo } = nuxtApp.$config.public;

  for (const [key, clientConfig] of Object.entries(apollo.clients)) {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        });
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    });

    const accessToken = useCookie('auth.access_token');

    const authLink = setContext((_, { headers }) => {
      const { selectedStoreIds } = storeToRefs(useMerchantStore());

      if (!accessToken.value) {
        return {
          headers,
        };
      }

      return {
        headers: {
          ...headers,
          authorization: `Bearer ${accessToken.value}`,
          'x-store-id': selectedStoreIds.value,
        },
      };
    });

    const httpLink = createHttpLink({
      uri: clientConfig.httpEndpoint,
      useGETForQueries: false,
    });

    clients[key] = new ApolloClient({
      cache: new InMemoryCache(),
      link: from([errorLink, authLink, httpLink]),
      ...(process.server ? { ssrMode: true } : { ssrForceFetchDelay: 100 }),
    });
  }

  const defaultClient = clients?.default;

  provideApolloClients(clients);
  nuxtApp.vueApp.provide(ApolloClients, clients);
  nuxtApp.vueApp.provide(DefaultApolloClient, clients.default);
  nuxtApp.vueApp.use(createApolloProvider({ defaultClient }) as any);
  nuxtApp._apolloClients = clients;

  return {
    provide: {
      apollo: { clients, defaultClient },
    },
  };
});
