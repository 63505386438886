import { firstValueFrom } from 'rxjs';

import { useApplicationStore } from '~/store/application';
import { useUserStore } from '~/store/user';

export default defineNuxtRouteMiddleware(async to => {
  const store = useApplicationStore();
  const user = useUserStore();

  if (!user.loading && store.ready) {
    return;
  }

  if (to.fullPath.startsWith('/login')) {
    return;
  }

  await firstValueFrom(store.load());

  return;
});
