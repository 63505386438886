import { gql } from '@apollo/client/core';

export const restartValdation = gql`
  mutation restartValidation($domain: String!) {
    restartValidation(domain: $domain) {
      status
      domain
      storeName
      recordName
      recordValue
      contactEmail
      loadBalancerAddress
      storeName
    }
  }
`;
