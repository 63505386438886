import type { ROLE_ID } from '@auth';
import type { ValueOf } from 'kafkajs';

import type { LoginResponse, PaginatinatedApiResponse } from '~/types';

import GatewayBase from './gateway-base';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
  lastActiveAt: Date;
  storeRoles: { storeId: string; storeRoleId: number }[];
  fullName: string;
  roleId: number;
  nickname: string;
};
export type UserInfo = {
  email_verified: boolean;
  email: string;
  family_name: string;
  gender: string;
  given_name: string;
  name: string;
  phone_number_verified: boolean;
  phone_number: string;
  picture: string;
  profile: string;
  sub: string;
  updated_at: string;
  website: string;
  zoneinfo: string;
};

type ErrorResponse = {
  error: string;
  errorCode: string;
};

export default class IdentityService extends GatewayBase {
  get users() {
    return {
      me: this.me.bind(this),
    };
  }

  get teamMembers() {
    return {
      counts: this.getTeamMemberCounts.bind(this),
      findOne: this.findOneUser.bind(this),
      list: this.getTeamMembers.bind(this),
    };
  }

  get admin() {
    return {
      findOne: this.findOneUser.bind(this),
      userCounts: this.getAdminUsersCounts.bind(this),
      users: this.getAdminUsers.bind(this),
    };
  }

  get auth() {
    return {
      login: this.login.bind(this),
      refresh: this.refreshToken.bind(this),
    };
  }

  private findOneUser(id: string) {
    return this.client.get<User>(`/v1/users/${id}`);
  }

  private login(email: string, password: string) {
    return this.client.post<LoginResponse & Partial<ErrorResponse>>('/v1/auth/login', { email, password });
  }

  private refreshToken(refreshToken: string) {
    return this.client.post<LoginResponse & Partial<ErrorResponse>>('/v1/auth/refresh', { refreshToken });
  }

  private getAdminUsersCounts() {
    return this.client.get<{ active: number; inactive: number }>('/v1/admin/users/counts');
  }

  private getTeamMemberCounts(filter: { storeId: string; accountId: string; status: 'active' | 'inactive' }) {
    return this.client.get<{ active: number; inactive: number }>('/v1/users/counts', {
      params: {
        filter,
      },
    });
  }

  private getTeamMembers(params: any) {
    return this.client.get<
      PaginatinatedApiResponse<{
        id: string;
        email: string;
        lastActiveAt: Date;
        roleId: ValueOf<typeof ROLE_ID>;
      }>
    >('/v1/users', { params });
  }

  private getAdminUsers(params: any) {
    return this.client.get<
      PaginatinatedApiResponse<{
        id: string;
        email: string;
        lastActiveAt: Date;
        roleId: ValueOf<typeof ROLE_ID>;
      }>
    >('/v1/admin/users', { params });
  }

  private me() {
    return this.client.get<UserInfo>('/v1/auth/userinfo');
  }
}
