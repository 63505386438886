
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="3000.000000pt" height="933.000000pt" viewBox="0 0 3000.000000 933.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,933.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M2477 9302 c-9 -9 -333 -787 -720 -1727 -925 -2244 -1178 -2857
-1483 -3589 -140 -334 -254 -619 -254 -632 0 -48 31 -64 123 -64 69 0 87 3
101 19 9 10 87 191 172 402 281 695 537 1304 556 1324 18 20 33 20 1008 23
819 2 995 0 1022 -11 30 -13 39 -29 107 -188 325 -773 404 -949 460 -1036 149
-231 390 -415 631 -483 176 -49 200 -50 1295 -57 1087 -6 1186 -4 1336 38 310
86 508 349 596 789 14 75 17 190 20 947 l4 862 26 21 c24 19 39 20 263 20 212
0 240 -2 259 -17 12 -11 198 -417 446 -977 464 -1043 473 -1064 549 -1181 120
-184 257 -310 431 -395 116 -56 225 -87 363 -100 125 -12 1315 -12 1449 0 362
33 558 169 640 445 17 56 18 183 20 2150 l3 2090 21 18 c25 20 68 22 91 5 9
-7 106 -186 216 -398 110 -212 476 -916 814 -1565 338 -649 794 -1524 1013
-1945 219 -421 407 -775 418 -787 20 -22 28 -23 172 -23 134 0 154 2 168 18
14 15 938 2127 1809 4132 133 308 219 493 235 508 28 25 59 28 88 6 20 -15 20
-30 25 -1907 l6 -1892 26 -105 c121 -485 346 -700 784 -750 70 -8 446 -10
1289 -8 1164 4 1192 5 1280 25 335 78 583 287 758 638 24 50 234 518 466 1041
355 800 426 953 447 963 34 15 464 15 498 0 13 -7 29 -23 35 -38 7 -17 12
-312 14 -876 4 -935 2 -904 68 -1120 107 -349 318 -554 634 -616 100 -20 2229
-21 2353 -1 324 51 577 198 761 442 91 121 123 188 361 750 124 293 231 533
242 543 19 16 83 17 1028 17 952 0 1008 -1 1023 -18 18 -20 291 -671 544
-1297 91 -225 174 -419 185 -430 15 -17 32 -20 97 -20 137 0 149 35 75 211
-290 690 -897 2158 -1819 4399 -393 957 -575 1387 -592 1403 -23 21 -33 22
-161 22 -99 -1 -142 -4 -155 -14 -9 -7 -162 -357 -338 -777 -647 -1540 -1509
-3597 -1821 -4344 -98 -236 -191 -452 -206 -479 -15 -27 -58 -82 -95 -123 -53
-56 -87 -82 -145 -110 -68 -34 -83 -38 -152 -37 -239 2 -420 230 -460 579 -6
59 -10 987 -10 2662 0 1864 -3 2579 -11 2596 -6 14 -22 30 -36 36 -31 14
-1650 15 -2023 1 -833 -32 -1403 -170 -1801 -435 -116 -78 -287 -238 -369
-346 -199 -264 -292 -590 -277 -963 18 -428 154 -716 459 -972 176 -147 375
-257 623 -343 272 -95 625 -161 861 -161 70 0 74 -1 74 -23 0 -22 -5 -23 -122
-34 -582 -56 -1024 -309 -1220 -698 -14 -27 -187 -408 -383 -845 -197 -437
-366 -811 -376 -831 -88 -174 -338 -280 -560 -239 -185 35 -337 196 -393 415
-42 168 -41 79 -41 2818 l0 2619 -30 25 -30 26 -275 -6 c-379 -9 -555 -39
-724 -123 -174 -87 -320 -247 -425 -464 -22 -47 -334 -740 -692 -1540 -359
-800 -714 -1594 -790 -1765 -77 -170 -147 -316 -156 -322 -9 -7 -32 -13 -50
-13 -27 0 -37 7 -57 38 -13 20 -272 514 -576 1097 -1107 2122 -1264 2422
-1316 2505 -70 114 -195 262 -283 335 -247 206 -474 260 -1064 253 -269 -3
-269 -3 -288 -27 -19 -23 -19 -79 -19 -2610 0 -1715 -3 -2615 -10 -2671 -43
-354 -203 -561 -456 -590 -247 -28 -456 80 -549 283 -18 40 -182 405 -365 812
-183 407 -348 772 -367 810 -132 263 -339 447 -653 576 -183 76 -355 114 -643
144 -47 5 -58 10 -55 23 3 14 24 18 138 24 356 19 739 113 1050 259 282 133
557 378 684 610 139 254 186 616 126 974 -51 300 -192 561 -421 779 -327 311
-792 485 -1505 565 -313 35 -532 41 -1541 41 -940 0 -984 -1 -1001 -18 -18
-18 -19 -77 -25 -1333 -4 -723 -7 -1902 -7 -2620 0 -1424 1 -1401 -57 -1564
-48 -135 -142 -254 -241 -304 -65 -33 -158 -54 -211 -46 -111 15 -257 126
-338 258 -18 29 -201 455 -407 947 -340 814 -744 1778 -1389 3315 -129 308
-308 733 -396 945 -94 223 -170 392 -182 403 -18 14 -43 17 -164 17 -123 0
-144 -2 -158 -18z m5526 -217 c301 -39 497 -134 697 -334 157 -158 240 -301
305 -526 85 -293 98 -699 34 -1031 -89 -465 -354 -793 -759 -939 -158 -56
-233 -67 -521 -72 -261 -5 -267 -5 -288 16 l-21 21 0 1420 c0 1407 0 1420 20
1440 19 19 33 20 219 20 120 0 246 -6 314 -15z m14543 -10 l24 -24 -2 -1420
c-3 -1350 -4 -1421 -21 -1438 -16 -16 -40 -18 -255 -17 -171 0 -264 5 -334 17
-434 74 -751 330 -907 730 -82 214 -114 408 -114 707 0 234 15 378 58 545 128
491 464 814 935 899 122 22 132 23 373 24 l218 2 25 -25z m-20523 -1666 c30
-14 9 33 252 -549 111 -267 297 -713 414 -991 116 -278 211 -517 211 -532 0
-15 -9 -36 -20 -47 -20 -20 -33 -20 -884 -20 l-865 0 -20 26 c-12 15 -21 33
-21 40 0 8 109 276 241 596 133 321 323 781 424 1023 147 357 186 442 206 452
30 16 32 16 62 2z m26048 -19 c8 -10 86 -188 172 -396 86 -209 273 -662 417
-1008 143 -346 260 -639 260 -652 0 -13 -9 -33 -20 -44 -20 -20 -33 -20 -884
-20 -772 0 -866 2 -880 16 -35 35 -30 56 72 300 554 1329 736 1763 749 1787
24 48 80 56 114 17z"/>
<path d="M8860 1896 c-141 -40 -266 -152 -342 -306 -78 -157 -113 -319 -124
-565 -7 -147 6 -335 32 -460 53 -255 174 -449 330 -532 l62 -33 191 0 192 0
60 30 c158 80 272 274 295 500 l7 70 -110 0 -109 0 -12 -74 c-33 -215 -132
-324 -303 -334 -199 -11 -315 110 -375 392 -39 184 -39 533 1 721 58 275 197
415 389 391 153 -20 246 -123 284 -318 l18 -93 105 0 104 0 -2 61 c-4 150 -71
312 -173 415 -106 108 -213 150 -380 148 -52 0 -115 -6 -140 -13z"/>
<path d="M10378 1900 c-139 -35 -260 -144 -337 -302 -91 -187 -116 -332 -116
-658 1 -272 13 -371 67 -535 56 -173 158 -310 277 -374 l59 -31 182 0 182 0
59 32 c120 63 227 215 283 398 37 124 56 254 63 435 17 452 -90 804 -288 950
-93 68 -154 88 -284 91 -60 2 -127 -1 -147 -6z m258 -230 c110 -54 181 -195
215 -430 16 -110 16 -491 0 -595 -29 -187 -78 -309 -153 -382 -53 -52 -110
-73 -197 -73 -197 0 -306 170 -342 531 -20 213 -2 532 41 689 36 133 125 245
219 275 60 19 160 12 217 -15z"/>
<path d="M13340 1896 c-229 -66 -371 -281 -432 -651 -20 -124 -17 -515 6 -640
51 -285 167 -490 325 -573 l59 -32 182 0 182 0 60 32 c36 19 84 58 121 98 162
175 237 475 224 889 -14 454 -135 730 -370 846 -76 38 -83 39 -195 42 -75 1
-133 -2 -162 -11z m266 -227 c105 -50 172 -175 211 -389 24 -134 24 -533 0
-674 -41 -233 -125 -371 -248 -406 -44 -13 -134 -13 -178 0 -122 35 -206 172
-245 401 -35 202 -30 542 10 736 52 252 169 373 348 358 33 -3 79 -14 102 -26z"/>
<path d="M14831 1894 c-168 -45 -296 -177 -336 -345 -17 -73 -19 -194 -4 -258
42 -185 200 -322 474 -411 205 -67 311 -135 356 -227 19 -38 23 -63 22 -128
-3 -230 -129 -346 -361 -333 -178 10 -287 117 -315 309 l-11 80 -106 -3 -105
-3 2 -76 c6 -199 117 -379 287 -464 l69 -35 204 0 205 0 68 36 c160 84 254
229 281 433 27 198 -38 360 -186 461 -63 43 -171 94 -285 135 -221 78 -314
138 -363 230 -31 58 -31 202 0 261 28 52 90 108 139 125 62 21 200 17 254 -7
100 -46 154 -120 174 -237 l12 -72 105 0 104 0 3 34 c6 63 -19 171 -59 251
-64 127 -185 219 -327 249 -73 15 -236 13 -301 -5z"/>
<path d="M11595 1878 c-3 -7 -4 -431 -3 -943 l3 -930 498 -3 497 -2 0 105 0
105 -387 2 -388 3 -5 835 -5 835 -103 3 c-76 2 -104 -1 -107 -10z"/>
<path d="M15914 1877 c-2 -8 -3 -56 -2 -108 l3 -94 232 -3 233 -2 2 -833 3
-832 104 -3 c77 -2 107 1 113 10 4 7 8 383 8 836 l0 822 230 0 230 0 5 23 c3
12 5 60 3 107 l-3 85 -578 3 c-474 2 -578 0 -583 -11z"/>
<path d="M17505 1878 c-3 -7 -4 -431 -3 -943 l3 -930 108 -3 107 -3 0 446 0
445 134 0 134 0 133 -312 c73 -172 157 -371 188 -443 l56 -130 118 -3 c98 -2
117 0 117 12 0 8 -84 214 -186 456 -102 243 -184 442 -182 443 1 1 30 15 63
32 216 107 312 399 216 655 -59 155 -201 255 -399 281 -111 14 -602 11 -607
-3z m654 -224 c114 -34 166 -118 166 -269 -1 -76 -5 -99 -27 -147 -52 -112
-140 -141 -413 -136 l-160 3 -3 283 -2 282 193 0 c146 0 206 -4 246 -16z"/>
<path d="M18964 1877 c-3 -8 -3 -331 -2 -718 4 -784 2 -756 76 -907 44 -91
128 -179 214 -221 l61 -31 202 0 202 0 64 32 c89 44 171 128 214 220 72 153
70 122 70 918 l0 715 -105 0 -105 0 -5 -705 c-5 -694 -6 -706 -28 -771 -51
-154 -142 -219 -302 -219 -137 0 -221 42 -273 137 -61 113 -60 103 -66 863
l-6 695 -103 3 c-80 2 -104 0 -108 -11z"/>
<path d="M20545 1878 c-3 -7 -4 -431 -3 -943 l3 -930 100 0 100 0 5 749 5 749
120 -277 c67 -156 127 -282 137 -287 25 -13 132 -11 150 4 9 6 66 131 128 277
61 146 116 269 121 274 5 6 9 -278 9 -742 l0 -752 100 0 100 0 -2 943 -3 942
-110 3 c-60 1 -115 -1 -121 -5 -7 -5 -77 -170 -157 -368 l-145 -359 -14 34
c-8 19 -73 179 -143 355 -70 176 -135 326 -143 333 -11 8 -52 12 -124 12 -78
0 -110 -4 -113 -12z"/>
</g>
</svg>

  </template>

  <script>
  export default {
    name: 'ArmraLogo',
    inheritAttrs: true,
  }
  </script>
