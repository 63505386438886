import {
  ClaimsRestService,
  DispatchService,
  IdentityService,
  MetadataService,
  MerchantService,
} from '~/support/services';

export default function useGateway() {
  const baseUrl = useRuntimeConfig().public.gatewayBaseUrl as string;

  const dispatch = new DispatchService(baseUrl);
  const identity = new IdentityService(baseUrl);
  const merchant = new MerchantService(baseUrl);
  const claims = new ClaimsRestService(baseUrl);
  const metadata = new MetadataService(baseUrl);

  return {
    claims,
    dispatch,
    identity,
    merchant,
    metadata,
  };
}
