export default function useStorage<T>(key: string) {
  const cookie = useCookie<T>(key);

  function get() {
    if (import.meta.client) {
      const value = localStorage.getItem(key);

      return value ? JSON.parse(value) : null;
    }

    return cookie.value;
  }

  function set(value: T) {
    if (import.meta.client) {
      localStorage.setItem(key, JSON.stringify(value));

      return;
    }

    cookie.value = value;
  }

  return {
    get,
    set,
  };
}
