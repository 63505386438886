import { useApolloClient } from '@vue/apollo-composable';

import * as Mutations from '~/support/graphql/mutations';
import * as Queries from '~/support/graphql/queries';

export default function useGQL(cachePolicy?: string) {
  const loading = ref(false);

  const availableQueries = Queries;
  const availableMutations = Mutations;

  const { client } = useApolloClient('default');

  const query = (templateName: keyof typeof Queries, variables: object, options: any = {}) => {
    loading.value = true;

    return client
      .query({
        fetchPolicy: options.cachePolicy ?? (cachePolicy as any),
        query: availableQueries[templateName] as any,
        variables,
        ...options,
      })
      .finally(() => (loading.value = false));
  };

  const mutate = (templateName: keyof typeof Mutations, variables: object) => {
    loading.value = true;

    return client
      .mutate({
        mutation: availableMutations[templateName] as any, // i know i'm right here but typescript doesn't
        variables,
      })
      .finally(() => (loading.value = false));
  };

  return {
    loading,
    mutate,
    query,
  };
}
