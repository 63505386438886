import { defineStore } from 'pinia';
import { catchError, filter, from, of, switchMap, tap, throwError } from 'rxjs';

import { setTheme, removeTheme } from '~/support/utils/theme-switch';

import { useMerchantStore } from './merchant';
import { useUserStore } from './user';

const darkTheme = {
  '--danger-100': '#981B25',
  '--danger-700': '#FCA6AD',
  '--danger-800': '#F8727D',
  '--danger-900': '#EF4352',
  '--neutral-100': '#23272F',
  '--neutral-200': '#2C313A',
  '--neutral-300': '#3F444D',
  '--neutral-700': '#E1E6EF',
  '--neutral-800': '#F1F3F9',
  '--neutral-900': '#F8F9FC',
  '--neutral-white': '#1B1F27',
  '--primary-100': '#341278',
  '--primary-700': '#9B9BFD',
  '--primary-800': '#8383FB',
  '--primary-900': '#6C6CF9',
  '--secondary-100': '#F8F5FF',
  '--secondary-700': '#8B54F7',
  '--success-100': '#066042',
  '--success-700': '#EDFDF8',
  '--success-800': '#04724D',
  '--success-900': '#066042',
  '--typography-disabled': 'rgba(255, 255, 255, 0.60)',
  '--typography-primary': 'rgba(255, 255, 255, 0.925)',
  '--typography-secondary': 'rgba(255, 255, 255, 0.75)',
  '--warning-100': '#80460D',
  '--warning-700': '#FDCF72',
  '--warning-800': '#FBBB3C',
  '--warning-900': '#DB7712',
};

/**
 * Application store
 * stores application wide state, such as menu states and filters
 */
export const useApplicationStore = defineStore('application', () => {
  const userStore = useUserStore();
  const merchantStore = useMerchantStore();
  const userTheme = useCookie('user.theme');
  const showSearchModal = ref(false);
  const mobileMenuOpen = ref(false);
  const loading = ref(true);
  const theme = ref('');
  const language = ref('en');
  const searchQuery = ref('');
  const ready = ref(false);

  function openSearchModal() {
    showSearchModal.value = true;
  }

  function closeSearchModal() {
    showSearchModal.value = false;
  }

  function isSearchModalOpen() {
    return showSearchModal.value;
  }

  function toggleMobileMenu() {
    mobileMenuOpen.value = !mobileMenuOpen.value;
  }

  function closeMenu() {
    mobileMenuOpen.value = false;
  }

  function openMenu() {
    mobileMenuOpen.value = true;
  }

  function toggleTheme() {
    theme.value = theme.value === 'light' ? 'dark' : 'light';

    if (!import.meta.client) return;

    if (theme.value === 'light') {
      userTheme.value = 'light';
      removeTheme(darkTheme);
    } else {
      userTheme.value = 'dark';
      setTheme(darkTheme);
    }
  }

  function choose(choice: string) {
    theme.value = choice;

    if (!import.meta.client) return;
    if (theme.value === 'light') {
      removeTheme(darkTheme);
    } else {
      setTheme(darkTheme);
    }
  }

  function handleLoad() {
    if (import.meta.client) {
      const wantsDarkTheme = window.matchMedia('(prefers-color-scheme: dark)');

      if (wantsDarkTheme && !userTheme.value) {
        choose('dark');
        theme.value = 'dark';
      } else if (userTheme.value) {
        choose(userTheme.value);
        theme.value = userTheme.value;
      } else {
        choose('light');
        theme.value = 'light';
      }
    }

    // ready.value = true;
    return userStore.init().pipe(
      filter(v => !!v),
      switchMap(() => merchantStore.loadStores()),
    );
  }

  return {
    closeMenu,
    closeSearchModal,
    isSearchModalOpen,
    language,
    load: handleLoad,
    loading,
    mobileMenuOpen,
    openMenu,
    openSearchModal,
    ready,
    searchQuery,
    setTheme: choose,
    showSearchModal,
    theme,
    toggleMobileMenu,
    toggleTheme,
  };
});
