import { catchError, Observable, throwError, of } from 'rxjs';

import { UnauthorizedException } from '../utils';

export function catchUnauthorized(cb: (error: UnauthorizedException) => Observable<unknown>) {
  return catchError(error => {
    if (error instanceof UnauthorizedException) {
      return cb(error);
    }

    return throwError(() => error);
  });
}
