import { gql } from '@apollo/client/core';

export const accounts = gql`
  query {
    accounts {
      id
      name
    }
  }
`;
