import GatewayBase from './gateway-base';

export default class ClaimsRestService extends GatewayBase {
  private claimsForOrder(orderId: string) {
    return this.client.get<any[]>(`/v1/claims/order/${orderId}`);
  }

  private getPolicyByOrderId(orderId: string) {
    return this.client.get<any>(`/v1/policies/${orderId}`);
  }

  private createPolicy(data: any) {
    return this.client.post<any>('/v1/policies', data);
  }

  get policies() {
    return {
      create: this.createPolicy.bind(this),
      forOrder: this.getPolicyByOrderId.bind(this),
    };
  }
  get claims() {
    return {
      forOrder: this.claimsForOrder.bind(this),
    };
  }
}
