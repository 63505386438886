import { gql } from '@apollo/client/core';

export const storeCreate = gql`
  mutation CreateStore($input: StoreCreateInput!) {
    storeCreate(input: $input) {
      id
      name
      accountId
      storeUrl
      storeDomain
    }
  }
`;

export const storeCreateShopify = gql`
  mutation CreateShopifyStore($input: ShopifyStoreCreateInput!) {
    storeCreateShopify(input: $input) {
      id
      name
      accountId
      storeUrl
      storeDomain
    }
  }
`;

export const storeCreateBigcommerce = gql`
  mutation createBigcommerceStore($input: BigcommerceStoreCreateInput!) {
    storeCreateBigcommerce(input: $input) {
      id
      name
      accountId
      storeUrl
      storeDomain
    }
  }
`;
