import { ENTITLEMENTS, ROLE_ID } from '@auth';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import { storeToRefs } from 'pinia';

import { useMerchantStore } from '~/store/merchant';
import { useUserStore } from '~/store/user';

export const hasAccountEntitlements = (entitlements: string[] = []): boolean => {
  const store = useUserStore();
  const { user } = storeToRefs(store);

  if (!entitlements.length) return true;
  if (!user.value) return false;
  if (!user.value.entitlements?.length) return false;

  // @ts-ignore
  return intersection(entitlements, user.value.entitlements).length === entitlements.length;
};

export const hasStoreEntitlements = (storeId: string, ...entitlements: string[]): boolean => {
  const store = useUserStore();
  const { user } = storeToRefs(store);
  if (!user.value) return false;

  const storeEntitlements = get(user.value.storeRoleEntitlementsMap, [storeId], []);

  if (!entitlements.length) return true;
  if (!storeEntitlements.length) return false;

  return intersection(entitlements, storeEntitlements).length === entitlements.length;
};

const canDo = (...entitlements: string[]): boolean => {
  const merchantStore = useMerchantStore();
  const { currentStore } = storeToRefs(merchantStore);

  if (hasAccountEntitlements(entitlements)) return true;

  if (Array.isArray(currentStore.value)) {
    return currentStore.value.some(store => hasStoreEntitlements(store!.id, ...entitlements));
  }

  return hasStoreEntitlements(currentStore.value?.id!, ...entitlements);
};

// Define a type for the ENTITLEMENTS object
type EntitlementsType = typeof ENTITLEMENTS;

// Define the type for the `can` function
type Can = ((...entitlements: string[]) => boolean) & {
  [K in keyof EntitlementsType]: {
    [SK in keyof EntitlementsType[K]]: EntitlementsType[K][SK];
  };
};

// Create an object to hold the entitlements
const canProps: Partial<Can> = {};

// Dynamically assign properties from ENTITLEMENTS to canProps
Object.keys(ENTITLEMENTS).forEach(key => {
  const mainKey = key as keyof EntitlementsType;
  canProps[mainKey] = {} as any;

  Object.keys(ENTITLEMENTS[mainKey]).forEach(subKey => {
    (canProps[mainKey] as any)[subKey] = ENTITLEMENTS[mainKey][subKey as keyof EntitlementsType[typeof mainKey]];
  });
});

// Assign the properties to the canDo function
export const can = Object.assign(canDo, canProps) as Can;
