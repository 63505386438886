import VueDOMPurifyHTML from 'vue-dompurify-html';

export default defineNuxtPlugin(nuxtApp => {
  if (import.meta.client) {
    nuxtApp.vueApp.use(VueDOMPurifyHTML, {
      hooks: {
        afterSanitizeAttributes: function (node: Element) {
          if (node.tagName === 'A') {
            // set all `a` elements to open new tab
            node.setAttribute('target', '_blank');
            node.setAttribute('rel', 'noopener');
          }
        },
      },
    });
  }
});
