export function setTheme(theme: Record<string, string>) {
  Object.keys(theme).forEach((key: string) => {
    document.documentElement.style.setProperty(key, theme[key]);
  });
}

export function removeTheme(theme: Record<string, string>) {
  Object.keys(theme).forEach(key => {
    document.documentElement.style.removeProperty(key);
  });
}
