import { useUserStore } from '~/store/user';

export default defineNuxtRouteMiddleware(async to => {
  const store = useUserStore();
  if (to.fullPath.startsWith('/login')) {
    return;
  }

  try {
    const user = useCookie('auth.access_token');
    const expired = store.sessionExpired;

    /**
     * If the session has expired, we will attempt to refresh the session
     * and if that fails, we will log the user out and redirect them to
     * the login page.
     *
     * We could use a timeout to refresh the session, but we will use
     * the refresh token to refresh the session when the session has
     * expired. This means that the one redirect will be the only
     * slow redirect
     */
    if (expired) {
      try {
        await store.refreshSession();

        return;
      } catch {
        store.logout();

        return navigateTo('/login');
      }
    }

    if (!user.value) {
      return navigateTo('/login');
    } else if (user.value && to.fullPath === '/login') {
      return navigateTo('/claims/todo');
    }

    return;
  } catch {
    return navigateTo('/login');
  }
});
