import { gql } from '@apollo/client/core';

export const login = gql`
  mutation login($email: String!, $password: String!) {
    userLogin(input: { email: $email, password: $password }) {
      access_token
      expires_in
      refresh_token
      scope
      id_token
    }
  }
`;

export const changePasswordWithToken = gql`
  mutation changePassword($input: PasswordChangeInput!) {
    passwordChange(input: $input)
  }
`;

export const sendChangePasswordRequest = gql`
  mutation sendChangePasswordRequest($email: String!) {
    passwordChangeRequest(email: $email)
  }
`;
