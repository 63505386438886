import { ENTITLEMENTS } from './entitlements.constants';

export const ROLES = {
  accountAdmin: 'account_admin',
  accountOwner: 'account_owner',
  accountUser: 'account_user',
  opAdmin: 'orderprotection_admin',
  opGlobalSupport: 'orderprotection_support',
  opSupportAdmin: 'orderprotection_support_admin',
  opFinance: 'orderprotection_finance',
};

export const ROLE_ID = {
  accountAdmin: 1,
  accountOwner: 2,
  accountUser: 3,
  opAdmin: 4,
  opGlobalSupport: 5,
  opSupportAdmin: 6,
  opFinance: 7,
};

export const STORE_ROLES = {
  storeAdmin: 'store_admin',
  storeOwner: 'store_owner',
  storeSupport: 'store_support',
  storeUser: 'store_user',
};

export const STORE_ROLE_ID = {
  storeAdmin: 1,
  storeOwner: 2,
  storeSupport: 3,
  storeUser: 4,
};

const EVERY_ENTITLEMENT = Object.values(ENTITLEMENTS).reduce((acc, entitlement) => {
  return [...acc, ...Object.values(entitlement)];
}, []);

const EXCLUDE_ENTITLEMENT = [ENTITLEMENTS.financeAdmin.read, ENTITLEMENTS.financeAdmin.update];

export const ROLE_ENTITLEMENTS_MAP = {
  [ROLES.opAdmin]: EVERY_ENTITLEMENT.filter(entitlement => !EXCLUDE_ENTITLEMENT.includes(entitlement)),
  [ROLES.opFinance]: EVERY_ENTITLEMENT,
  [ROLES.accountOwner]: [
    ...Object.values(ENTITLEMENTS.metadata),
    ...Object.values(ENTITLEMENTS.label),
    ENTITLEMENTS.protection.add,
    ENTITLEMENTS.claimFiling.read,
    ENTITLEMENTS.accountStores.read,
    ENTITLEMENTS.branding.read,
    ENTITLEMENTS.branding.update,
    ENTITLEMENTS.teamMember.changeRole,
    ENTITLEMENTS.teamMember.grantOwnership,
    ENTITLEMENTS.teamMember.invite,
    ENTITLEMENTS.teamMember.edit,
    ENTITLEMENTS.teamMember.remove,
    ENTITLEMENTS.settingsGeneral.read,
    ENTITLEMENTS.settingsRevShare.read,
    ENTITLEMENTS.macro.create,
    ENTITLEMENTS.macro.delete,
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.macro.update,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.store.update,
    ENTITLEMENTS.user.create,
    ENTITLEMENTS.user.delete,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.user.update,
    ENTITLEMENTS.order.read,
    ENTITLEMENTS.contact.update,
    ENTITLEMENTS.contact.read,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.update,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claimNote.create,
    ENTITLEMENTS.claimNote.delete,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.claimNote.update,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.analytics.read,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.payout.read,
    ENTITLEMENTS.finance.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.account.update,
    ENTITLEMENTS.invoice.read,
    ENTITLEMENTS.paymentMethod.create,
    ENTITLEMENTS.paymentMethod.delete,
    ENTITLEMENTS.paymentMethod.read,
    ENTITLEMENTS.paymentMethod.update,
    ENTITLEMENTS.payoutMethod.create,
    ENTITLEMENTS.payoutMethod.delete,
    ENTITLEMENTS.payoutMethod.read,
    ENTITLEMENTS.payoutMethod.update,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.webhook.create,
    ENTITLEMENTS.webhook.delete,
    ENTITLEMENTS.webhook.read,
    ENTITLEMENTS.webhook.update,
    ENTITLEMENTS.billing.read,
    ENTITLEMENTS.settingsCustomerCommunication.read,
    ENTITLEMENTS.settingsCustomerCommunication.update,
    ENTITLEMENTS.storeCredit.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.update,
    ENTITLEMENTS.settingsWidget.read,
    ENTITLEMENTS.settingsWidget.update,
    ENTITLEMENTS.settingsFulfillment.read,
    ENTITLEMENTS.settingsFulfillment.update,
    ENTITLEMENTS.settingsReplacementCost.read,
    ENTITLEMENTS.settingsReplacementCost.update,
    ENTITLEMENTS.apiTokens.create,
    ENTITLEMENTS.apiTokens.delete,
    ENTITLEMENTS.apiTokens.read,
    ENTITLEMENTS.apiTokens.update,
    ENTITLEMENTS.teamMember.read,
    ENTITLEMENTS.abTests.read,
    ENTITLEMENTS.abTests.delete,
    ENTITLEMENTS.abTests.update,
    ENTITLEMENTS.abTests.create,
  ],
  [ROLES.accountAdmin]: [
    ...Object.values(ENTITLEMENTS.metadata),
    ...Object.values(ENTITLEMENTS.label),
    ENTITLEMENTS.protection.add,
    ENTITLEMENTS.claimFiling.read,
    ENTITLEMENTS.accountStores.read,
    ENTITLEMENTS.branding.read,
    ENTITLEMENTS.branding.update,
    ENTITLEMENTS.contact.update,
    ENTITLEMENTS.contact.read,
    ENTITLEMENTS.teamMember.changeRole,
    ENTITLEMENTS.teamMember.invite,
    ENTITLEMENTS.teamMember.edit,
    ENTITLEMENTS.teamMember.remove,
    ENTITLEMENTS.settingsGeneral.read,
    ENTITLEMENTS.settingsRevShare.read,
    ENTITLEMENTS.macro.create,
    ENTITLEMENTS.macro.delete,
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.macro.update,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.store.update,
    ENTITLEMENTS.user.create,
    ENTITLEMENTS.user.delete,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.user.update,
    ENTITLEMENTS.order.read,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.update,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claimNote.create,
    ENTITLEMENTS.claimNote.delete,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.claimNote.update,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.analytics.read,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.payout.read,
    ENTITLEMENTS.finance.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.account.update,
    ENTITLEMENTS.invoice.read,
    ENTITLEMENTS.paymentMethod.create,
    ENTITLEMENTS.paymentMethod.delete,
    ENTITLEMENTS.paymentMethod.read,
    ENTITLEMENTS.paymentMethod.update,
    ENTITLEMENTS.payoutMethod.create,
    ENTITLEMENTS.payoutMethod.delete,
    ENTITLEMENTS.payoutMethod.read,
    ENTITLEMENTS.payoutMethod.update,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.webhook.create,
    ENTITLEMENTS.webhook.delete,
    ENTITLEMENTS.webhook.read,
    ENTITLEMENTS.webhook.update,
    ENTITLEMENTS.billing.read,
    ENTITLEMENTS.settingsCustomerCommunication.read,
    ENTITLEMENTS.settingsCustomerCommunication.update,
    ENTITLEMENTS.storeCredit.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.update,
    ENTITLEMENTS.settingsWidget.read,
    ENTITLEMENTS.settingsWidget.update,
    ENTITLEMENTS.settingsFulfillment.read,
    ENTITLEMENTS.settingsFulfillment.update,
    ENTITLEMENTS.settingsReplacementCost.read,
    ENTITLEMENTS.settingsReplacementCost.update,
    ENTITLEMENTS.apiTokens.create,
    ENTITLEMENTS.apiTokens.delete,
    ENTITLEMENTS.apiTokens.read,
    ENTITLEMENTS.apiTokens.update,
    ENTITLEMENTS.teamMember.read,
    ENTITLEMENTS.abTests.read,
    ENTITLEMENTS.abTests.delete,
    ENTITLEMENTS.abTests.update,
    ENTITLEMENTS.abTests.create,
  ],
  [ROLES.accountUser]: [
    ENTITLEMENTS.claimFiling.read,
    ENTITLEMENTS.teamMember.read,
    ENTITLEMENTS.contact.read,
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.user.update,
    ENTITLEMENTS.order.read,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.storeCredit.read,
    ENTITLEMENTS.webhook.read,
  ],
  [ROLES.opGlobalSupport]: [
    ENTITLEMENTS.metadata.update,
    ENTITLEMENTS.metadata.create,
    ENTITLEMENTS.metadata.read,
    ENTITLEMENTS.claimFiling.read,
    ENTITLEMENTS.support.memberRead,
    ENTITLEMENTS.contact.read,
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.user.update,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claim.update,
    ENTITLEMENTS.order.read,
    ENTITLEMENTS.claimNote.create,
    ENTITLEMENTS.claimNote.delete,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.claimNote.update,
    ENTITLEMENTS.message.create,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.storeCredit.read,
    ENTITLEMENTS.adminStores.read,
  ],
  [ROLES.opSupportAdmin]: [
    ...Object.values(ENTITLEMENTS.metadata),
    ...Object.values(ENTITLEMENTS.label),
    ENTITLEMENTS.protection.add,
    ENTITLEMENTS.claimFiling.read,
    ENTITLEMENTS.claimFiling.update,
    ENTITLEMENTS.adminStores.read,
    ENTITLEMENTS.support.memberRead,
    ENTITLEMENTS.support.memberCreate,
    ENTITLEMENTS.support.memberUpdate,
    ENTITLEMENTS.support.memberDelete,
    ENTITLEMENTS.contact.read,
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.update,
    ENTITLEMENTS.order.read,
    ENTITLEMENTS.claimNote.create,
    ENTITLEMENTS.claimNote.update,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.message.create,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.branding.read,
    ENTITLEMENTS.settingsCustomerCommunication.read,
    ENTITLEMENTS.settingsFulfillment.read,
    ENTITLEMENTS.settingsReplacementCost.read,
    ENTITLEMENTS.settingsWidget.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.read,
    ENTITLEMENTS.settingsGeneral.read,
    ENTITLEMENTS.settingsRevShare.read,
    ENTITLEMENTS.teamMember.read,
    ENTITLEMENTS.apiTokens.read,
    ENTITLEMENTS.webhook.read,
    ENTITLEMENTS.paymentMethod.read,
    ENTITLEMENTS.payoutMethod.read,
    ENTITLEMENTS.analytics.read,
    ENTITLEMENTS.invoice.read,
    ENTITLEMENTS.billing.read,
    ENTITLEMENTS.payout.read,
    ENTITLEMENTS.capabilities.read,
    ENTITLEMENTS.macro.create,
    ENTITLEMENTS.macro.update,
  ],
};

export const STORE_ROLE_ENTITLEMENTS_MAP = {
  [STORE_ROLES.storeOwner]: [
    ...Object.values(ENTITLEMENTS.metadata),
    ...Object.values(ENTITLEMENTS.label),
    ENTITLEMENTS.protection.add,
    ENTITLEMENTS.branding.read,
    ENTITLEMENTS.branding.update,
    ENTITLEMENTS.teamMember.changeRole,
    ENTITLEMENTS.teamMember.grantOwnership,
    ENTITLEMENTS.teamMember.invite,
    ENTITLEMENTS.teamMember.edit,
    ENTITLEMENTS.teamMember.remove,
    ENTITLEMENTS.settingsGeneral.read,
    ENTITLEMENTS.macro.create,
    ENTITLEMENTS.macro.delete,
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.macro.update,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.store.update,
    ENTITLEMENTS.user.create,
    ENTITLEMENTS.user.delete,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.user.update,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claim.update,
    ENTITLEMENTS.claimNote.create,
    ENTITLEMENTS.claimNote.delete,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.claimNote.update,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.finance.read,
    ENTITLEMENTS.analytics.read,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.payout.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.invoice.read,
    ENTITLEMENTS.paymentMethod.create,
    ENTITLEMENTS.paymentMethod.delete,
    ENTITLEMENTS.paymentMethod.read,
    ENTITLEMENTS.paymentMethod.update,
    ENTITLEMENTS.payoutMethod.create,
    ENTITLEMENTS.payoutMethod.delete,
    ENTITLEMENTS.payoutMethod.read,
    ENTITLEMENTS.payoutMethod.update,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.webhook.create,
    ENTITLEMENTS.webhook.delete,
    ENTITLEMENTS.webhook.read,
    ENTITLEMENTS.webhook.update,
    ENTITLEMENTS.order.read,
    ENTITLEMENTS.billing.read,
    ENTITLEMENTS.settingsCustomerCommunication.read,
    ENTITLEMENTS.settingsCustomerCommunication.update,
    ENTITLEMENTS.storeCredit.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.update,
    ENTITLEMENTS.settingsWidget.read,
    ENTITLEMENTS.settingsWidget.update,
    ENTITLEMENTS.settingsFulfillment.read,
    ENTITLEMENTS.settingsFulfillment.update,
    ENTITLEMENTS.settingsReplacementCost.read,
    ENTITLEMENTS.settingsReplacementCost.update,
    ENTITLEMENTS.apiTokens.create,
    ENTITLEMENTS.apiTokens.delete,
    ENTITLEMENTS.apiTokens.read,
    ENTITLEMENTS.apiTokens.update,
    ENTITLEMENTS.abTests.read,
    ENTITLEMENTS.abTests.delete,
    ENTITLEMENTS.abTests.update,
    ENTITLEMENTS.abTests.create,
  ],
  [STORE_ROLES.storeAdmin]: [
    ...Object.values(ENTITLEMENTS.metadata),
    ...Object.values(ENTITLEMENTS.label),
    ENTITLEMENTS.protection.add,
    ENTITLEMENTS.branding.read,
    ENTITLEMENTS.branding.update,
    ENTITLEMENTS.teamMember.changeRole,
    ENTITLEMENTS.teamMember.invite,
    ENTITLEMENTS.teamMember.edit,
    ENTITLEMENTS.teamMember.remove,
    ENTITLEMENTS.settingsGeneral.read,
    ENTITLEMENTS.macro.create,
    ENTITLEMENTS.macro.delete,
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.macro.update,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.store.update,
    ENTITLEMENTS.user.create,
    ENTITLEMENTS.user.delete,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.user.update,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.update,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claimNote.create,
    ENTITLEMENTS.claimNote.delete,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.claimNote.update,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.analytics.read,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.payout.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.invoice.read,
    ENTITLEMENTS.finance.read,
    ENTITLEMENTS.paymentMethod.create,
    ENTITLEMENTS.paymentMethod.delete,
    ENTITLEMENTS.paymentMethod.read,
    ENTITLEMENTS.paymentMethod.update,
    ENTITLEMENTS.payoutMethod.create,
    ENTITLEMENTS.payoutMethod.delete,
    ENTITLEMENTS.payoutMethod.read,
    ENTITLEMENTS.payoutMethod.update,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.webhook.create,
    ENTITLEMENTS.webhook.delete,
    ENTITLEMENTS.webhook.read,
    ENTITLEMENTS.webhook.update,
    ENTITLEMENTS.order.read,
    ENTITLEMENTS.billing.read,
    ENTITLEMENTS.settingsCustomerCommunication.read,
    ENTITLEMENTS.settingsCustomerCommunication.update,
    ENTITLEMENTS.storeCredit.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.read,
    ENTITLEMENTS.settingsOneTimeAppeasements.update,
    ENTITLEMENTS.settingsWidget.read,
    ENTITLEMENTS.settingsWidget.update,
    ENTITLEMENTS.settingsFulfillment.read,
    ENTITLEMENTS.settingsFulfillment.update,
    ENTITLEMENTS.settingsReplacementCost.read,
    ENTITLEMENTS.settingsReplacementCost.update,
    ENTITLEMENTS.apiTokens.create,
    ENTITLEMENTS.apiTokens.delete,
    ENTITLEMENTS.apiTokens.read,
    ENTITLEMENTS.apiTokens.update,
    ENTITLEMENTS.abTests.read,
    ENTITLEMENTS.abTests.delete,
    ENTITLEMENTS.abTests.update,
    ENTITLEMENTS.abTests.create,
  ],
  [STORE_ROLES.storeSupport]: [
    ...Object.values(ENTITLEMENTS.metadata),
    ...Object.values(ENTITLEMENTS.label),
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.user.update,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claim.update,
    ENTITLEMENTS.claimNote.create,
    ENTITLEMENTS.claimNote.delete,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.claimNote.update,
    ENTITLEMENTS.message.create,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.storeCredit.read,
    ENTITLEMENTS.order.read,
  ],
  [STORE_ROLES.storeUser]: [
    ENTITLEMENTS.metadata.create,
    ENTITLEMENTS.metadata.read,
    ENTITLEMENTS.macro.read,
    ENTITLEMENTS.store.read,
    ENTITLEMENTS.user.read,
    ENTITLEMENTS.user.update,
    ENTITLEMENTS.claim.create,
    ENTITLEMENTS.claim.update,
    ENTITLEMENTS.claim.read,
    ENTITLEMENTS.claimNote.create,
    ENTITLEMENTS.claimNote.delete,
    ENTITLEMENTS.claimNote.read,
    ENTITLEMENTS.claimNote.update,
    ENTITLEMENTS.message.read,
    ENTITLEMENTS.notifications.read,
    ENTITLEMENTS.account.read,
    ENTITLEMENTS.policies.read,
    ENTITLEMENTS.quotePricing.read,
    ENTITLEMENTS.webhook.read,
    ENTITLEMENTS.order.read,
    ENTITLEMENTS.storeCredit.read,
  ],
};
