<template>
  <button
    :class="classes"
    :disabled="buttonIsDisabled"
    class="flex items-center gap-1 rounded-md justify-center font-normal"
    style="box-shadow: 0px 1px 4px 0px #0000001f"
    :style="{
      background: variant === 'primary ' ? 'linear-gradient(90deg, #5b1fd3 4.17%, #44179d 100%)' : '',
    }"
  >
    <slot name="icon-left" v-if="!loading" />

    <slot v-if="!loading" />
    <slot name="loading" v-if="loading">
      <PulseLoadingSpinner />
    </slot>

    <slot v-if="$slots['icon-right'] && !loading" name="icon-right" class="h-3 w-3" />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  round: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'medium',
    validator: (size: string) => ['x-small', 'small', 'medium', 'large', 'fluid'].includes(size),
  },
  variant: {
    type: String,
    validator: (variant: string) => ['primary', 'secondary', 'destructive'].includes(variant),
    default: 'primary',
  },
  iconLeft: {
    type: [String, Object],
    default: null,
  },
  iconRight: {
    type: [String, Object],
    default: null,
  },
});

const buttonIsDisabled = computed(() => {
  return props.disabled || props.loading;
});

const classes = computed(() => {
  return {
    'rounded-md': props.round,
    'rounded-full': props.round,
    'primary-gradient text-white hover:bg-primary-800 active:bg-primary-900 pulse-primary-button':
      props.variant === 'primary',
    'bg-neutral-white border border-neutral-100 text-typography-primary hover:bg-neutral-100 active:bg-neutral-200':
      props.variant === 'secondary',
    'bg-danger-700 text-white hover:bg-danger-800 active:bg-danger-900': props.variant === 'destructive',
    'px-3 py-[6px] text-xs': props.size === 'x-small',
    'px-3 py-2 text-xs': props.size === 'small',
    'px-3 py-2 text-sm': props.size === 'medium',
    'px-4 py-3 text-md': props.size === 'large' || props.size === 'fluid',
    'w-full': props.size === 'fluid',
    'opacity-75 pointer-events-none': buttonIsDisabled.value,
  };
});
</script>
