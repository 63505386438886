import { default as indexl8eFT6TXXOMeta } from "/app/apps/valet/pages/[storeSlug]/finance/admin/index.vue?macro=true";
import { default as indexDk8t9K9W2iMeta } from "/app/apps/valet/pages/[storeSlug]/finance/admin/payments/index.vue?macro=true";
import { default as indexnQJwxYAqPSMeta } from "/app/apps/valet/pages/[storeSlug]/finance/admin/payouts/index.vue?macro=true";
import { default as adminxkdSgIfLPSMeta } from "/app/apps/valet/pages/[storeSlug]/finance/admin.vue?macro=true";
import { default as indexyxbLWiIVS1Meta } from "/app/apps/valet/pages/[storeSlug]/finance/payments/index.vue?macro=true";
import { default as indexWIv0VUoZzBMeta } from "/app/apps/valet/pages/[storeSlug]/finance/payments/invoices/index.vue?macro=true";
import { default as _91id_93EF6wsecPWrMeta } from "/app/apps/valet/pages/[storeSlug]/finance/payments/invoices/view/[id].vue?macro=true";
import { default as index7XgxFFn8s5Meta } from "/app/apps/valet/pages/[storeSlug]/finance/payments/manage-by-store/index.vue?macro=true";
import { default as indexH27wdCC2QdMeta } from "/app/apps/valet/pages/[storeSlug]/finance/payments/payment-methods/index.vue?macro=true";
import { default as paymentsJzk5zu35CfMeta } from "/app/apps/valet/pages/[storeSlug]/finance/payments.vue?macro=true";
import { default as not_45reimbursediargFFaid4Meta } from "/app/apps/valet/pages/[storeSlug]/finance/payouts/[id]/not-reimbursed.vue?macro=true";
import { default as reimbursed6vAbYsRZnTMeta } from "/app/apps/valet/pages/[storeSlug]/finance/payouts/[id]/reimbursed.vue?macro=true";
import { default as revenue_45shareLzHhhE0o80Meta } from "/app/apps/valet/pages/[storeSlug]/finance/payouts/[id]/revenue-share.vue?macro=true";
import { default as _91id_93tv1E1UpaMwMeta } from "/app/apps/valet/pages/[storeSlug]/finance/payouts/[id].vue?macro=true";
import { default as indexh69y86eGVTMeta } from "/app/apps/valet/pages/[storeSlug]/finance/payouts/index.vue?macro=true";
import { default as payoutsVCrsxZeK5XMeta } from "/app/apps/valet/pages/[storeSlug]/finance/payouts.vue?macro=true";
import { default as _91type_93kkfjKhhIW2Meta } from "/app/apps/valet/pages/[storeSlug]/macros/[type].vue?macro=true";
import { default as indexjZo9lM63noMeta } from "/app/apps/valet/pages/[storeSlug]/macros/index.vue?macro=true";
import { default as payouts1zremfNIhAMeta } from "/app/apps/valet/pages/[storeSlug]/payouts.vue?macro=true";
import { default as indexdlXdoO6i3xMeta } from "/app/apps/valet/pages/[storeSlug]/settings/api-tokens/index.vue?macro=true";
import { default as brandingjqjzRNbzIfMeta } from "/app/apps/valet/pages/[storeSlug]/settings/branding.vue?macro=true";
import { default as capabilitiesfFXJLHisMCMeta } from "/app/apps/valet/pages/[storeSlug]/settings/capabilities.vue?macro=true";
import { default as contactxfLhyenw6NMeta } from "/app/apps/valet/pages/[storeSlug]/settings/contact.vue?macro=true";
import { default as contractsl5JyxkOkxOMeta } from "/app/apps/valet/pages/[storeSlug]/settings/contracts.vue?macro=true";
import { default as developerTedeotaTiXMeta } from "/app/apps/valet/pages/[storeSlug]/settings/developer.vue?macro=true";
import { default as generalf6vdvzLmDeMeta } from "/app/apps/valet/pages/[storeSlug]/settings/general.vue?macro=true";
import { default as indexw7nBBtfIAOMeta } from "/app/apps/valet/pages/[storeSlug]/settings/index.vue?macro=true";
import { default as integrationsmGDEJRV5a3Meta } from "/app/apps/valet/pages/[storeSlug]/settings/integrations.vue?macro=true";
import { default as policiesxR3B4h6dqEMeta } from "/app/apps/valet/pages/[storeSlug]/settings/policies.vue?macro=true";
import { default as warrantykFA5Sb959SMeta } from "/app/apps/valet/pages/[storeSlug]/settings/warranty.vue?macro=true";
import { default as indexfv3eaErcrXMeta } from "/app/apps/valet/pages/[storeSlug]/settings/webhooks/index.vue?macro=true";
import { default as _91id_93vpEWD0VhJjMeta } from "/app/apps/valet/pages/[storeSlug]/settings/webhooks/view/[id].vue?macro=true";
import { default as settingsI6KywnO9ZwMeta } from "/app/apps/valet/pages/[storeSlug]/settings.vue?macro=true";
import { default as activece7kKnxL3fMeta } from "/app/apps/valet/pages/[storeSlug]/team-members/active.vue?macro=true";
import { default as inactivehsQW1qPbmIMeta } from "/app/apps/valet/pages/[storeSlug]/team-members/inactive.vue?macro=true";
import { default as indexSMYmEFNwxtMeta } from "/app/apps/valet/pages/[storeSlug]/team-members/index.vue?macro=true";
import { default as _91id_93HV0PzCjxsqMeta } from "/app/apps/valet/pages/[storeSlug]/team-members/member/[id].vue?macro=true";
import { default as team_45membersxzO2do5uqpMeta } from "/app/apps/valet/pages/[storeSlug]/team-members.vue?macro=true";
import { default as index5uJdYPyt3DMeta } from "/app/apps/valet/pages/[storeSlug]/tests/index.vue?macro=true";
import { default as _91id_93eTVzxIAdkmMeta } from "/app/apps/valet/pages/[storeSlug]/tests/view/[id].vue?macro=true";
import { default as _91testId_93CczvH9qT89Meta } from "/app/apps/valet/pages/[storeSlug]/tests/view/[testId].vue?macro=true";
import { default as macrostbpgYJBGYKMeta } from "/app/apps/valet/pages/admin/macros.vue?macro=true";
import { default as activeDYyyi3FDdWMeta } from "/app/apps/valet/pages/admin/stores/active.vue?macro=true";
import { default as devCsJuLH21STMeta } from "/app/apps/valet/pages/admin/stores/dev.vue?macro=true";
import { default as inactives9iWbk9ggRMeta } from "/app/apps/valet/pages/admin/stores/inactive.vue?macro=true";
import { default as indexaIzFgetAswMeta } from "/app/apps/valet/pages/admin/stores/index.vue?macro=true";
import { default as onboardingXKMNzPOI7oMeta } from "/app/apps/valet/pages/admin/stores/onboarding.vue?macro=true";
import { default as stores1HgTMCdokzMeta } from "/app/apps/valet/pages/admin/stores.vue?macro=true";
import { default as _91type_93j7YnSGxp1vMeta } from "/app/apps/valet/pages/admin/users/[type].vue?macro=true";
import { default as indexXsfZP9ZO4mMeta } from "/app/apps/valet/pages/admin/users/index.vue?macro=true";
import { default as _91id_93TFLbwlG56HMeta } from "/app/apps/valet/pages/admin/users/user/[id].vue?macro=true";
import { default as usersF7Mo4VL1GMMeta } from "/app/apps/valet/pages/admin/users.vue?macro=true";
import { default as indexut9WfDQXpgMeta } from "/app/apps/valet/pages/admin/whitelabel/index.vue?macro=true";
import { default as whitelabellHqaeNEef7Meta } from "/app/apps/valet/pages/admin/whitelabel.vue?macro=true";
import { default as _91type_93hI5cMrsgURMeta } from "/app/apps/valet/pages/claims/[type].vue?macro=true";
import { default as indexzsOp77NQIMMeta } from "/app/apps/valet/pages/claims/index.vue?macro=true";
import { default as _91id_93PRwgEL459LMeta } from "/app/apps/valet/pages/claims/view/[id].vue?macro=true";
import { default as claimsm90NvwKB4XMeta } from "/app/apps/valet/pages/claims.vue?macro=true";
import { default as _91id_937nu0UmyYCaMeta } from "/app/apps/valet/pages/customers/[id].vue?macro=true";
import { default as indexLAe2Kr7w6WMeta } from "/app/apps/valet/pages/dashboard/index.vue?macro=true";
import { default as overview9ywDChaWH6Meta } from "/app/apps/valet/pages/dashboard/overview.vue?macro=true";
import { default as dashboard1HjSW8sbOwMeta } from "/app/apps/valet/pages/dashboard.vue?macro=true";
import { default as indexd7h9GexC8AMeta } from "/app/apps/valet/pages/index.vue?macro=true";
import { default as callbackZQqHE5PTHaMeta } from "/app/apps/valet/pages/login/callback.vue?macro=true";
import { default as indexlLcgxJePEBMeta } from "/app/apps/valet/pages/login/index.vue?macro=true";
import { default as not_45foundd0kpiGU6gVMeta } from "/app/apps/valet/pages/login/not-found.vue?macro=true";
import { default as reset96Pmn8IImOMeta } from "/app/apps/valet/pages/login/reset.vue?macro=true";
import { default as set_45passwordvpVaHPvWjqMeta } from "/app/apps/valet/pages/login/set-password.vue?macro=true";
import { default as welcomepMlGcZZlKdMeta } from "/app/apps/valet/pages/login/welcome.vue?macro=true";
import { default as loginrFEC3B28E7Meta } from "/app/apps/valet/pages/login.vue?macro=true";
import { default as indexhP61lIjRnNMeta } from "/app/apps/valet/pages/orders/index.vue?macro=true";
import { default as _91type_93Tm9L6VoQXMMeta } from "/app/apps/valet/pages/store-credit/[type].vue?macro=true";
import { default as indexils6thsTMEMeta } from "/app/apps/valet/pages/store-credit/index.vue?macro=true";
import { default as store_45creditl4hBGrwKmqMeta } from "/app/apps/valet/pages/store-credit.vue?macro=true";
import { default as unauthorizedI33blAZbzsMeta } from "/app/apps/valet/pages/unauthorized.vue?macro=true";
export default [
  {
    name: adminxkdSgIfLPSMeta?.name,
    path: "/:storeSlug()/finance/admin",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-finance-admin",
    path: "",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-admin-payments",
    path: "payments",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/admin/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-admin-payouts",
    path: "payouts",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/admin/payouts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: paymentsJzk5zu35CfMeta?.name,
    path: "/:storeSlug()/finance/payments",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payments.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-finance-payments",
    path: "",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payments-invoices",
    path: "invoices",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payments/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payments-invoices-view-id",
    path: "invoices/view/:id()",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payments/invoices/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payments-manage-by-store",
    path: "manage-by-store",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payments/manage-by-store/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payments-payment-methods",
    path: "payment-methods",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payments/payment-methods/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: payoutsVCrsxZeK5XMeta?.name,
    path: "/:storeSlug()/finance/payouts",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payouts.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-finance-payouts-id",
    path: ":id()",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payouts/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-finance-payouts-id-not-reimbursed",
    path: "not-reimbursed",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payouts/[id]/not-reimbursed.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payouts-id-reimbursed",
    path: "reimbursed",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payouts/[id]/reimbursed.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-finance-payouts-id-revenue-share",
    path: "revenue-share",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payouts/[id]/revenue-share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storeSlug-finance-payouts",
    path: "",
    component: () => import("/app/apps/valet/pages/[storeSlug]/finance/payouts/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storeSlug-macros-type",
    path: "/:storeSlug()/macros/:type()",
    component: () => import("/app/apps/valet/pages/[storeSlug]/macros/[type].vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-macros",
    path: "/:storeSlug()/macros",
    component: () => import("/app/apps/valet/pages/[storeSlug]/macros/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-payouts",
    path: "/:storeSlug()/payouts",
    component: () => import("/app/apps/valet/pages/[storeSlug]/payouts.vue").then(m => m.default || m)
  },
  {
    name: settingsI6KywnO9ZwMeta?.name,
    path: "/:storeSlug()/settings",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-settings-api-tokens",
    path: "api-tokens",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/api-tokens/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-branding",
    path: "branding",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/branding.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-capabilities",
    path: "capabilities",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/capabilities.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-contact",
    path: "contact",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/contact.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-contracts",
    path: "contracts",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/contracts.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-developer",
    path: "developer",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/developer.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-general",
    path: "general",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/general.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings",
    path: "",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-integrations",
    path: "integrations",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/integrations.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-policies",
    path: "policies",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/policies.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-warranty",
    path: "warranty",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/warranty.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-webhooks",
    path: "webhooks",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/webhooks/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-settings-webhooks-view-id",
    path: "webhooks/view/:id()",
    component: () => import("/app/apps/valet/pages/[storeSlug]/settings/webhooks/view/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: team_45membersxzO2do5uqpMeta?.name,
    path: "/:storeSlug()/team-members",
    component: () => import("/app/apps/valet/pages/[storeSlug]/team-members.vue").then(m => m.default || m),
    children: [
  {
    name: "storeSlug-team-members-active",
    path: "active",
    component: () => import("/app/apps/valet/pages/[storeSlug]/team-members/active.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-team-members-inactive",
    path: "inactive",
    component: () => import("/app/apps/valet/pages/[storeSlug]/team-members/inactive.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-team-members",
    path: "",
    meta: indexSMYmEFNwxtMeta || {},
    component: () => import("/app/apps/valet/pages/[storeSlug]/team-members/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-team-members-member-id",
    path: "member/:id()",
    component: () => import("/app/apps/valet/pages/[storeSlug]/team-members/member/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "storeSlug-tests",
    path: "/:storeSlug()/tests",
    component: () => import("/app/apps/valet/pages/[storeSlug]/tests/index.vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-tests-view-id",
    path: "/:storeSlug()/tests/view/:id()",
    component: () => import("/app/apps/valet/pages/[storeSlug]/tests/view/[id].vue").then(m => m.default || m)
  },
  {
    name: "storeSlug-tests-view-testId",
    path: "/:storeSlug()/tests/view/:testId()",
    component: () => import("/app/apps/valet/pages/[storeSlug]/tests/view/[testId].vue").then(m => m.default || m)
  },
  {
    name: "admin-macros",
    path: "/admin/macros",
    component: () => import("/app/apps/valet/pages/admin/macros.vue").then(m => m.default || m)
  },
  {
    name: stores1HgTMCdokzMeta?.name,
    path: "/admin/stores",
    component: () => import("/app/apps/valet/pages/admin/stores.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-stores-active",
    path: "active",
    component: () => import("/app/apps/valet/pages/admin/stores/active.vue").then(m => m.default || m)
  },
  {
    name: "admin-stores-dev",
    path: "dev",
    component: () => import("/app/apps/valet/pages/admin/stores/dev.vue").then(m => m.default || m)
  },
  {
    name: "admin-stores-inactive",
    path: "inactive",
    component: () => import("/app/apps/valet/pages/admin/stores/inactive.vue").then(m => m.default || m)
  },
  {
    name: "admin-stores",
    path: "",
    component: () => import("/app/apps/valet/pages/admin/stores/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-stores-onboarding",
    path: "onboarding",
    component: () => import("/app/apps/valet/pages/admin/stores/onboarding.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersF7Mo4VL1GMMeta?.name,
    path: "/admin/users",
    component: () => import("/app/apps/valet/pages/admin/users.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-users-type",
    path: ":type()",
    component: () => import("/app/apps/valet/pages/admin/users/[type].vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "",
    component: () => import("/app/apps/valet/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users-user-id",
    path: "user/:id()",
    component: () => import("/app/apps/valet/pages/admin/users/user/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: whitelabellHqaeNEef7Meta?.name,
    path: "/admin/whitelabel",
    component: () => import("/app/apps/valet/pages/admin/whitelabel.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-whitelabel",
    path: "",
    component: () => import("/app/apps/valet/pages/admin/whitelabel/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: claimsm90NvwKB4XMeta?.name,
    path: "/claims",
    component: () => import("/app/apps/valet/pages/claims.vue").then(m => m.default || m),
    children: [
  {
    name: "claims-type",
    path: ":type()",
    component: () => import("/app/apps/valet/pages/claims/[type].vue").then(m => m.default || m)
  },
  {
    name: "claims",
    path: "",
    meta: indexzsOp77NQIMMeta || {},
    component: () => import("/app/apps/valet/pages/claims/index.vue").then(m => m.default || m)
  },
  {
    name: "claims-view-id",
    path: "view/:id()",
    component: () => import("/app/apps/valet/pages/claims/view/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "customers-id",
    path: "/customers/:id()",
    component: () => import("/app/apps/valet/pages/customers/[id].vue").then(m => m.default || m)
  },
  {
    name: dashboard1HjSW8sbOwMeta?.name,
    path: "/dashboard",
    component: () => import("/app/apps/valet/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: "dashboard",
    path: "",
    meta: indexLAe2Kr7w6WMeta || {},
    component: () => import("/app/apps/valet/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-overview",
    path: "overview",
    component: () => import("/app/apps/valet/pages/dashboard/overview.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/apps/valet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginrFEC3B28E7Meta?.name,
    path: "/login",
    component: () => import("/app/apps/valet/pages/login.vue").then(m => m.default || m),
    children: [
  {
    name: "login-callback",
    path: "callback",
    component: () => import("/app/apps/valet/pages/login/callback.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "",
    meta: indexlLcgxJePEBMeta || {},
    component: () => import("/app/apps/valet/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-not-found",
    path: "not-found",
    component: () => import("/app/apps/valet/pages/login/not-found.vue").then(m => m.default || m)
  },
  {
    name: "login-reset",
    path: "reset",
    component: () => import("/app/apps/valet/pages/login/reset.vue").then(m => m.default || m)
  },
  {
    name: "login-set-password",
    path: "set-password",
    component: () => import("/app/apps/valet/pages/login/set-password.vue").then(m => m.default || m)
  },
  {
    name: "login-welcome",
    path: "welcome",
    meta: welcomepMlGcZZlKdMeta || {},
    component: () => import("/app/apps/valet/pages/login/welcome.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/app/apps/valet/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: store_45creditl4hBGrwKmqMeta?.name,
    path: "/store-credit",
    component: () => import("/app/apps/valet/pages/store-credit.vue").then(m => m.default || m),
    children: [
  {
    name: "store-credit-type",
    path: ":type()",
    component: () => import("/app/apps/valet/pages/store-credit/[type].vue").then(m => m.default || m)
  },
  {
    name: "store-credit",
    path: "",
    meta: indexils6thsTMEMeta || {},
    component: () => import("/app/apps/valet/pages/store-credit/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "unauthorized",
    path: "/unauthorized",
    component: () => import("/app/apps/valet/pages/unauthorized.vue").then(m => m.default || m)
  }
]